const focusHelper = (() => {
    let elementToFocus: HTMLElement | null | undefined;
    let focusOptions: FocusOptions | undefined;

    const focus = () => {
        if (!elementToFocus) { return; }
        const oldTabIndex = elementToFocus.tabIndex;
        const shouldOverrideTabIndex = oldTabIndex === undefined || oldTabIndex >= 0;
        if (shouldOverrideTabIndex) { elementToFocus.tabIndex = -1; }
        elementToFocus.focus(focusOptions);
        if (shouldOverrideTabIndex) { elementToFocus.tabIndex = oldTabIndex; }
    };

    const requestFocus = (element: HTMLElement | null | undefined, options?: FocusOptions) => {
        elementToFocus = element;
        focusOptions = options;
        if (!elementToFocus) {
            return;
        }
        if (window.webkit?.messageHandlers.focusBounce) {
            window.webkit.messageHandlers.focusBounce?.postMessage({});
        } else {
            focus();
        }
    };

    window.focusBounce = focus;

    return {
        requestFocus
    };
})();

export default focusHelper;
