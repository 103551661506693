export const KINDLE_STORE_COOKIE_NAME = 'x-amzn-kstore';

export function getCookieValue(name: string): string | undefined {
    if (!document?.cookie) {
        return undefined;
    }

    let value;
    document.cookie.split('; ').some((cookie) => {
        const parts = cookie.split('=');
        const key = parts[0];

        if (key === name) {
            value = parts.slice(1).join('=');
            return true;
        }
    });

    return value;
}
