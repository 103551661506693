import React, { useContext } from "react";
import TranslationsContext from "src/contexts/TranslationsContext";
import styles from "./RplUpsell.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import rplIconLight from "src/images/logos/rpl_icon_light.svg";
import rplIconDark from "src/images/logos/rpl_icon_dark.svg";
import { Theme } from "src/utils/themeUtils";

type PropTypes = {
    item: QuickViewAsinMetadata;
};

const RplUpsell: React.FC<PropTypes> = ({ item }: PropTypes) => {
    const translations = useContext(TranslationsContext);
    const deviceContext = useContext(DeviceContext);

    if (!item.isRPL) {
        return null;
    }

    const rplIcon = deviceContext.theme === Theme.DARK ? rplIconDark : rplIconLight;

    return (
        <div className={`${styles.rplUpsell} ${styles[deviceContext.theme]}`} >
            <div className={styles.rplLogo}>
                <img className={styles.rplIcon} src={rplIcon} alt={translations.getText("rpl-icon-alt-text")} />
            </div>
            <div className={styles.rplText}>
                {translations.getText("read-and-listen-with-your-membership")}
            </div>
        </div>
    );
};

export default RplUpsell;
