import React, { useContext } from "react";
import styles from "./EpisodesCard.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import { NativeAPI } from "src/utils/deviceUtils";
import { VellaFooterChevron } from "./VellaFooterChevron";
import BorrowManagerContext from "src/contexts/BorrowManagerContext";

interface PropTypes {
    vella: VellaProduct;
}

const EpisodeChevron = () => (
    <svg width="7px" height="14px" viewBox="0 0 7 14" version="1.1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-466, -142)" stroke="currentColor">
                <polyline points="466.572236 142.669929 472.321792 148.78591 466.572236 154.509519"></polyline>
            </g>
        </g>
    </svg>
);

const EpisodeKey = () => (
    <svg viewBox="0 0 52 29" aria-hidden="true">
        <path d="M14 29c-7.777-.272-14-6.66-14-14.5S6.223.272 14 .008V0h24c7.777.272 14 6.66 14 14.5s-6.223 14.228-14 14.492V29H14z" fill="#E37F0D"></path>
        <path d="M14 26v-.01C7.88 25.727 3 20.683 3 14.5S7.88 3.272 14 3.01V3h24v.01c6.12.263 11 5.307 11 11.49s-4.88 11.227-11 11.49V26H14z" fill="#FCEDC8"></path>
        <path d="M14.768 11.158h-.047a2.59 2.59 0 00-1.76 4.453c.5.48 1.173.756 1.85.726a2.59 2.59 0 001.826-.79 2.57 2.57 0 00.728-1.844 2.569 2.569 0 00-.793-1.818 2.587 2.587 0 00-1.804-.727zm5.547 4.167a5.714 5.714 0 01-1.4 2.415 5.739 5.739 0 01-4.152 1.754c-1.501 0-2.919-.57-4.003-1.613A5.702 5.702 0 019 13.848a5.702 5.702 0 011.618-4.093 5.743 5.743 0 014.048-1.754 5.734 5.734 0 014.106 1.612 5.705 5.705 0 011.542 2.555H43v3.158h-2.337l-.003 4.66-3.175-.002.008-4.66h-1.69l-.003 4.658-3.166.018v-4.692l-12.319.017z" fill="#E37F0D"></path>
    </svg>
);

const LoadingIcon = () => (
    <div className={styles.loadingIconWrapper}>
        <div className={styles.loadingIcon} aria-hidden="true" />
    </div>
);

const EpisodesCard: React.FC<PropTypes> = ({ vella }: PropTypes) => {
    const context = useContext(DeviceContext);
    const borrowManagerContext = useContext(BorrowManagerContext);
    const translations = useContext(TranslationsContext);

    if (!vella) return null;

    const firstDisplayedEpNum = vella.firstDisplayedEpisodeNumber || 0;
    const lastDisplayedEpNum = vella.lastDisplayedEpisodeNumber || 0;
    const seeAllEpisodes = () => {
        NativeAPI.openWebPage(`/kindle-vella/product/${vella.asin}/episodeList`);
    };

    return (<section className={`${styles.vellaEpisodes} ${styles[context.theme]}`}>
        <div className={styles.sectionTitle}>
            {translations.getText("vella-episodes-label")}
        </div>
        <ul className={styles.episodesList}>
            {vella.episodes?.filter(episode =>
                    episode.episodeNumber !== undefined
                    && episode.episodeNumber >= firstDisplayedEpNum
                    && episode.episodeNumber <= lastDisplayedEpNum
                )
                .map(episode => {
                    return (<li key={episode.asin} onClick={() => borrowManagerContext.openVellaEpisode(episode)} role="button" tabIndex={0}>
                        <div className={styles.episodeLabel}>{`${episode.episodeNumber}. ${episode.title}`}</div>
                        {borrowManagerContext.isOpening(episode.asin) && <LoadingIcon />}
                        {(episode.isFree || episode.hasOwnership)
                            ? <div><EpisodeChevron/></div>
                            : <div className={styles.lockedEpisode}><EpisodeKey/> {episode.falkorPrice }</div>
                        }
                    </li>);
                })
            }
        </ul>
        <hr className={styles.contentDivider} aria-hidden/>
        <div className={styles.seeAllEpisodes} onClick={seeAllEpisodes} role="button" tabIndex={0}>
        {translations.getText("vella-see-all-episodes-label")}
            <VellaFooterChevron/>
        </div>
    </section>);
};

export default EpisodesCard;
