import debug from "./debugUtils";

import { fetchAapiCsrfData } from "./ajaxUtils";

export type AapiCsrfData = {
    "marketplaceId"?: string;
    "aapiAjaxEndpoint"?: string;
    "aapiCsrfToken"?: string;
    "aapiSeigeProfileName"?: string;
    "encryptedSlateToken"?: string;
};

const aapiCsrfData: AapiCsrfData = {};

// TODO: Pull from page as an option on Android
export const getAapiCsrfData = async (options?: { forceRefresh: boolean }) => {
    if (options?.forceRefresh || !aapiCsrfData.aapiCsrfToken) {
        debug.log(`fetching AAPI CSRF data - forceRefresh: ${options?.forceRefresh}`);
        const freshData = await fetchAapiCsrfData();
        aapiCsrfData.marketplaceId = freshData.marketplaceId || aapiCsrfData.marketplaceId;
        aapiCsrfData.aapiAjaxEndpoint = freshData.aapiAjaxEndpoint || aapiCsrfData.aapiAjaxEndpoint;
        aapiCsrfData.aapiCsrfToken = freshData.aapiCsrfToken || aapiCsrfData.aapiCsrfToken;
        aapiCsrfData.aapiSeigeProfileName = freshData.aapiSeigeProfileName || aapiCsrfData.aapiSeigeProfileName;
        aapiCsrfData.encryptedSlateToken = freshData.encryptedSlateToken || aapiCsrfData.encryptedSlateToken;
    }
    debug.log(aapiCsrfData);
    return aapiCsrfData;
};
