import React from "react";
import styles from "./Spinner.module.scss";

type Props = {
    size?: string;
};

const Spinner: React.FC<Props> = (props) => {
    const size = props.size || "32px";

    return (
        <div className={styles.spinnerDiv}>
            <span
                className={styles.spinner}
                style={{
                    width: `${size}`,
                    height: `${size}`,
                    backgroundSize: `${size}`,
                }}
            />
        </div>
    );
};

export default Spinner;
