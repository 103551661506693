import React, { useContext } from "react";
import styles from "./StoryDetails.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import { StarRatingBar } from "../StarRatingBar/StarRatingBar";
import TranslationsContext from "src/contexts/TranslationsContext";
import { NativeAPI } from "src/utils/deviceUtils";

const vellaCategoriesMap: {[key: string]: string} = {
    '21364098011': 'falkor-category-fantasy',
    '21364104011': 'falkor-category-romance',
    '21364105011': 'falkor-category-science-fiction',
    '21364106011': 'falkor-category-teen-young-adult',
    '21364109011': 'falkor-category-paranormal',
    '21364102011': 'falkor-category-mystery',
    '21364101011': 'falkor-category-lgbt-fiction',
    '21364107011': 'falkor-category-thriller',
    '21364096011': 'falkor-category-action-adventure',
    '21364097011': 'falkor-category-dystopian',
    '21364108011': 'falkor-category-erotica',
    '21364099011': 'falkor-category-historical-fiction',
    '21364100011': 'falkor-category-humor',
    '21364103011': 'falkor-category-non-fiction',
    '21388294011': 'falkor-category-children',
    '107197267011': 'falkor-category-new-adult',
    '107197266011': 'falkor-category-horror',
};

interface PropTypes {
    onClickCustomerReviews: VoidFunction;
    reviewsSummary?: ReviewsSummary;
    vella: VellaProduct;
}

const openVellaCategory = (id: string) => {
    NativeAPI.openWebPage(`/kindle-vella/category/${id}`);
};

const openVellaTag = (tag: string) => {
    NativeAPI.openWebPage(`/kindle-vella/tags/${tag}`);
};

const StoryDetails: React.FC<PropTypes> = ({ vella, reviewsSummary, onClickCustomerReviews }: PropTypes) => {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);

    if (!vella) return null;

    const vellaCategories = vella.categoryIds?.map(id => {
            return {
                id,
                label: translations.getText(vellaCategoriesMap[id])
            };
        })
        .filter(it => it.label);

    const updateCount = vella.updateFrequency || 0;
    return (<section className={`${styles.vellaMoreDetails} ${styles[context.theme]}`}>
        <ul className={styles.vellaTags}>
            {vella.tags?.map((tag) => {
                return (<li key={tag} className={styles.vellaTagButton} onClick={() => openVellaTag(tag)} aria-label={translations.formatText("falkor-story-tag-aria-label", {tag})} role="button" tabIndex={0}>{tag}</li>);
            })}
        </ul>

        <table className={styles.detailsTable}>
            <tr className={styles.itemRow}>
                <td className={styles.itemLabel}>{translations.getText("vella-categories-label")}</td>
                <td><div className={styles.vellaCategories}>
                {vellaCategories?.map((category, index) => {
                    const button = (<div className={styles.vellaCategoryButton} onClick={() => openVellaCategory(category.id)} role="button" tabIndex={0}>{category.label}</div>);
                    if (index > 0) {
                        return (<>
                            <div className={styles.vellaCategorySeparator}>/</div>
                            {button}
                        </>);
                    }
                    return button;
                })}
                </div></td>
            </tr>
            {reviewsSummary && (
                <tr className={styles.itemRow}>
                    <td className={styles.itemLabel}>{translations.getText("vella-ratings-label")}</td>
                    <td><StarRatingBar reviewsSummary={reviewsSummary} onTapCallback={onClickCustomerReviews}/></td>
                </tr>
            )}
            {vella.lastUpdated && (<>
                <tr className={styles.itemRow}>
                    <td className={styles.itemLabel}>{translations.getText("vella-last-update-label")}</td>
                    <td>{vella.lastUpdated}</td>
                </tr>
                <tr className={styles.itemRow}>
                    <td className={styles.itemLabel}>{translations.getText("vella-update-frequency-label")}</td>
                    <td>{ updateCount
                        ? translations.formatText("vella-update-frequency-text", { updateCount })
                        : translations.getText("vella-update-frequency-no-updates-text")
                    }</td>
                </tr>
            </>)}
        </table>

    </section>);
};

export default StoryDetails;
