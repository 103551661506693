import debug from "./debugUtils";
import { NativeAPI } from "./deviceUtils";

let qvSessionId = "";
export const setQvSessionId = (id: string) => {
    debug.log(`setQvSessionId: ${id}`);
    qvSessionId = id;
};

// https://kindle-fast-metrics.aka.amazon.com/#/schemas/kindle_lcd_quick_view_metrics_v0/0
// https://kindle-fast-metrics.aka.amazon.com/#/schemas/kindle_lcd_quick_view_operational_metrics/0
const behavioralSchemaName = "kindle_lcd_quick_view_metrics_v0";
const operationalSchemaName = "kindle_lcd_quick_view_operational_metrics";
const behavioralSchemaVersion = 0;
const operationalSchemaVersion = 0;
const shouldBatchMetrics = typeof window.webkit?.messageHandlers.reportBatchFastMetrics?.postMessage === "function";
const batchedMetrics: BatchedMetric[] = [];
let enableMetrics = true;

type MetricsContext = {
    namespace: string;
    qv_asin?: string;
};

export type Metrics = {
    recordBehavioralMetric: (name: string, value: number) => void;
    recordOperationalMetric: (name: string, value: number, msg?: string) => void; 
    recordMetric: (name: string, value: number) => void;
    recordTimeFromStart: (name: string, endTime?: number) => void;
};

const MAX_BATCHED_METRICS = 50;
let batchFlushTimeout: ReturnType<typeof setTimeout>;
const enqueueMetric = (metric: BatchedMetric) => {
    if (shouldBatchMetrics) {
        batchedMetrics.push(metric);
        if (batchedMetrics.length < MAX_BATCHED_METRICS) {
            clearTimeout(batchFlushTimeout);
        }
        batchFlushTimeout = setTimeout(flushBatchedMetrics, 500);
    } else {
        NativeAPI.reportFastMetric(metric.schemaName, metric.schemaVersion, metric.payload);
    }
};

export const flushBatchedMetrics = () => {
    debug.log(`Flushing batched metrics with length: ${batchedMetrics.length}`);
    clearTimeout(batchFlushTimeout);
    if (batchedMetrics.length > 0) {
        NativeAPI.reportBatchFastMetrics(batchedMetrics);
    }
    batchedMetrics.length = 0;
};

export const disableMetrics = () => {
    enableMetrics = false;
};

export const recordBehavioralMetric = (context: MetricsContext, name: string, value: number) => {
    debug.log(`recordBehavioralMetric: ${context.namespace}:${context.qv_asin || ""}:${name}:${value}`);
    if (!enableMetrics) { return; }
    enqueueMetric({
        schemaName: behavioralSchemaName,
        schemaVersion: behavioralSchemaVersion,
        payload: {
            namespace: context.namespace,
            name,
            type: "decimal",
            decimal_value: value,
            qv_asin: context.qv_asin,
            qv_session_id: qvSessionId,
        },
    });
};

export const recordOperationalMetric = (context: MetricsContext, name: string, value: number, msg?: string) => {
    debug.log(`recordOperationalMetric: ${context.namespace}:${context.qv_asin || ""}:${name}:${value}`);
    if (!enableMetrics) { return; }
    enqueueMetric({
        schemaName: operationalSchemaName,
        schemaVersion: operationalSchemaVersion,
        payload: {
            namespace: context.namespace,
            name,
            decimal_value: value,
            qv_asin: context.qv_asin,
            qv_session_id: qvSessionId,
            message: msg,
        },
    });
};

// Outputs to both behavioral and operation schemas
// Drops the metric if the value is NaN
export const recordMetric = (context: MetricsContext, name: string, value: number) => {
    debug.log(`recordMetric: ${context.namespace}:${context.qv_asin || ""}:${name}:${value}`);
    if (!enableMetrics || isNaN(value)) { return; }
    recordBehavioralMetric(context, name, value);
    recordOperationalMetric(context, name, value);
};

export const elapsed = (start?: number, end?: number) => {
    return (end || NaN) - (start || NaN);
}

export const recordTimeFromStart = (context: MetricsContext, name: string, endTime?: number) => {
    debug.log(`recordTimeFromStart: ${context.namespace}:${context.qv_asin || ""}:${name}:${endTime}`);
    if (!enableMetrics) { return; }
    recordMetric(context, `launchTo.${name}`, elapsed(window.quickViewData?.startTime, endTime));
    recordMetric(context, `markupTo.${name}`, elapsed(window.qv?.htmlStartTime, endTime));
};

export const newMetricsWithContext = (namespace: string, asin?: string): Metrics =>  {
    const context: MetricsContext = {
        namespace,
        qv_asin: asin,
    };
    return {
        recordBehavioralMetric: (name: string, value: number) => recordBehavioralMetric(context, name, value),
        recordOperationalMetric: (name: string, value: number, msg?: string) => recordOperationalMetric(context, name, value, msg),
        recordMetric: (name: string, value: number) => recordMetric(context, name, value),
        recordTimeFromStart: (name: string, endTime?: number) => recordTimeFromStart(context, name, endTime),
    };
};

debug.log(`Metrics.QvSessionId: ${qvSessionId}`);
