// Defaults only count when localStorage.getItem(enableDebugOptions) is true
const defaultOptions: string[] = [
    "enableDebugGooey",
    "enableVisibleErrorBoundary"
];

const optionCache = new Map<string, boolean>();

const enableDebugOptions = process.env.NODE_ENV == "development" || window.localStorage.getItem("enableDebugGooey") === "true";

const doNothing = () => { /* do nothing */ };

const getAsBoolean = (key: string) => {
    if (!enableDebugOptions) {
        return false;
    }
    let cachedValue = optionCache.get(key);
    if (cachedValue === undefined) {
        const value = window.localStorage.getItem(key) ?? defaultOptions.includes(key);
        cachedValue = (value === true || value === "true");
        optionCache.set(key, cachedValue);
    }
    return cachedValue;
};

const setAsBoolean = (key: string, value: boolean) => {
    debug.log(`setAsBoolean: ${key} -  ${value}`);
    optionCache.set(key, value);
    window.localStorage.setItem(key, value.toString());
};

const getAsString = (key: string) => {
    if (!enableDebugOptions) {
        return undefined;
    }
    return window.localStorage.getItem(key);
};

const setAsString = (key: string, value: string) => {
    debug.log(`setAsString: ${key} -  ${value}`);
    window.localStorage.setItem(key, value);
};

const enableDebugLogs = process.env.NODE_ENV == "development" || getAsBoolean("enableDebugLogs");
const enableErrorLogs = enableDebugLogs || getAsBoolean("enableErrorLogs");

const errorLogs: string[] = [];
const logError = function (...args: any) {
    errorLogs.push(`${new Date().toISOString()} ${JSON.stringify(args)}`);
    console.error(args);
};
const debug_log = enableDebugLogs ? console.log : doNothing;
const debug_error = enableErrorLogs ? logError : doNothing;

const scripts: string[] = [];
document.querySelectorAll('script')
    .forEach(it => scripts.push(
        it.src
            .replace(window.location.origin, "")
            .replace("https://quickview.kindle.amazon.dev", "")
    ));

const getMainJs = () => {
    const maybeMainJs = scripts.find(it => it.includes("/main."));
    if (maybeMainJs) {
        return `main_${maybeMainJs.split("/main.")[1].replace(/\./g, "_")}`;
    }
    return "";
};

const debug = {
    isDebugLoggingEnabled: enableDebugLogs,
    log: debug_log,
    error: debug_error,
    get: getAsBoolean,
    set: setAsBoolean,
    getString: getAsString,
    setString: setAsString,
    versionInfo: {
        buildDate: process.env.BUILD_TIME,
        scripts,
        mainJs: getMainJs(),
    },
    errorLogs: errorLogs,
};

debug.log(debug.versionInfo);

export default debug;
