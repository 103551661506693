import React, { ReactNode, useEffect, useState } from "react";
import TranslationsContext from "src/contexts/TranslationsContext";
import debug from "src/utils/debugUtils";
import rawDefaults from "src/i18n/strings.puff.json";

const defaults: { resources: Record<string, { value: string }> } = rawDefaults;

const i18nHashCode = (() => {
    const value = JSON.stringify(defaults);
    let hash = value.length;
    for (let i = 0; i < value.length; i += 1) {
        hash = ((hash << 5) - hash + value.charCodeAt(i)) | 0;
    }
    return hash;
})();

const getTranslationsUrlBase = () => {
    const betaHost = "https://development.quickview.kindle.amazon.dev";
    const gammaHost = "https://pre-prod.quickview.kindle.amazon.dev";
    const prodHost = "https://quickview.kindle.amazon.dev";
    const searchString = window.location.search;
    const origin = window.location.origin;
    if (searchString.indexOf("domain=beta")  != -1 || origin === betaHost ) { return betaHost; }
    if (searchString.indexOf("domain=gamma") != -1 || origin === gammaHost) { return gammaHost; }
    if (searchString.indexOf("domain=prod")  != -1 || origin === prodHost ) { return prodHost; }
    if (window.location.port === "4321") { return origin; }
    return prodHost;
};

type TranslationBundle = Record<string, { strings: Record<string, string> }>;
const translationsMap = new Map<string, TranslationBundle>();
const fetchTranslationsForLocale = async (locale: string) => {
    const language = locale.split("-")[0];
    if (translationsMap.has(language)) {
        return translationsMap.get(language);
    }
    return fetch(`${getTranslationsUrlBase()}/i18n/translation-${language}.json?v=${i18nHashCode}`, {
            headers: {
                // 7 days = 604,800s = 60s * 60m * 24h * 7d.
                // Check for updated translations every 7 days, but allow another 7 days of staleness while revalidating
                "Cache-Control" : "max-age=604800, stale-while-revalidate=604800",
            },
        })
        .then((response) => response.text())
        .then(JSON.parse)
        .then((translations: TranslationBundle) => {
            translationsMap.set(language, translations);
            return translations;
        });
};

type PropTypes = {
    locale: string;
    children?: React.ReactNode;
};
const Translations: React.FC<PropTypes> = ({ locale, children }) => {
    const language = locale.split("-")[0];
    const [isBundleLoaded, setBundleLoaded] = useState(false);
    const [translations, setTranslations] = useState<TranslationBundle>();

    useEffect(() => {
        fetchTranslationsForLocale(locale)
            .then((bundle) => { setTranslations(bundle); setBundleLoaded(true); })
            .catch(debug.error);
    }, [locale]);

    const getValueForKey = (key: string) => {
        if (translations) {
            return translations[locale]?.strings[key] || translations[language]?.strings[key];
        }
    };

    const getText = (messageKey: string) => {
        const defaultText = defaults.resources[messageKey]?.value || "";
        try {
            return getValueForKey(messageKey) || defaultText;
        } catch (e) {
            debug.error(e);
            return defaultText;
        }
    };

    const formatText = (messageKey: string, args: Record<string, unknown>) => {
        const defaultText = "";
        try {
            let translation = getValueForKey(messageKey) || defaults.resources[messageKey]?.value || defaultText;
            for (const [key, value] of Object.entries(args)) {
                translation = translation.replace(new RegExp("\\{" + key + "\\}", "g"), value as string);
            }
            return translation;
        } catch (e) {
            debug.error(e);
            return defaultText;
        }
    };

    return (
        <TranslationsContext.Provider value={{ getText, formatText, isBundleLoaded }}>
            {children}
        </TranslationsContext.Provider>
    );
};

export default Translations;

// TODO: consider making this more flexible (more than one key, allow multiple matches, etc.) if there are other use cases for it.
export const interpolateTranslation = (base: string, key: string, value: ReactNode): JSX.Element => {
    const keyString = `{${key}}`;
    const keyIndex = base.indexOf(keyString);
    if (keyIndex === -1) {
        return (<>{base}</>);
    }
    return (<>
        { (keyIndex > 0) && base.substring(0, keyIndex)}
        { value }
        { ((keyIndex + keyString.length) < base.length) && base.substring(keyIndex + keyString.length)}
    </>);
}

export const releaseDateString = (locale: string, date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
    return date.toLocaleDateString(locale, options);
};
