import React, { forwardRef } from "react";
import styles from "./Button.module.scss";

type PropTypes = {
    onClick: VoidFunction;
    showProgressBar?: boolean;
    progressPercentage?: number;
    backgroundColor?: string;
    ariaLabel?: string;
    children?: React.ReactNode;
};

const Button = forwardRef<HTMLButtonElement, PropTypes>(function Button({ onClick, showProgressBar, progressPercentage, backgroundColor, ariaLabel, children }, ref) {
    return (
        <button
            type="button"
            onClick={onClick}
            className={`${styles.button} ${showProgressBar ? styles.inProgress : ""}`}
            style={{ backgroundColor: backgroundColor }}
            aria-label={ariaLabel}
            ref={ref}
        >
            <div className={styles.childContainer}>
                <div className={styles.progressBarContainer}>
                    <div className={styles.progressBar} style={{ width: `${progressPercentage ?? 0}%` }} />
                </div>
                {children}
            </div>
        </button>
    );
});

export default Button;
