const mathRandomAndDateFallback = () => {
    const getNextRandHex = (() => {
        let jiggle = Date.now();
        return () => {
            if (!jiggle) {
                jiggle = performance?.now() || Date.now();
            }
            jiggle = (Math.random() * 16 + jiggle) & 15;
            return jiggle;
        };
    })();

    return "00000000-0000-4000-8000-000000000000".replace(/[08]/g, (c) => {
        const r = getNextRandHex();
        return (c !== '8' ? r : r >> 2).toString(16);
    });
};

export const uuidv4 = () => {
    if (crypto && crypto.randomUUID) {
        return crypto.randomUUID();
    }
    return mathRandomAndDateFallback();
};
