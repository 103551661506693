// https://easings.net/#easeOutCubic
const easeOutCubic = (x: number) => 1 - (1 - x) ** 3;

// Calls your updateCallback with an animation frame sync'd value going from 0 to 1 over the given duration in milliseconds.
// isCancelled is check at the start of each loop to see if the caller has cancelled early
export const requestAnimationFrameEasedCallback = (duration: number, updateCallback: (val: number) => void, isCancelled: () => boolean) => {
    const startTime = Date.now();

    let animationFrameId = -1;

    const loop = () => {
        if (isCancelled()) {
            cancelAnimationFrame(animationFrameId);
            return;
        }
        const elapsed = Date.now() - startTime;
        const percentComplete = easeOutCubic(Math.min(Math.max(elapsed / duration, 0), 1));
        updateCallback(percentComplete);

        if (elapsed < duration && percentComplete < 1) {
            animationFrameId = requestAnimationFrame(loop);
        }
    };

    requestAnimationFrame(loop);
};
