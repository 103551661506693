import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./BuyBox.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import Spinner from "../Spinner/Spinner";
import { newMetricsWithContext } from "src/utils/metricsUtils";
import { interpolateTranslation } from "../Translations/Translations";
import debug from "src/utils/debugUtils";
import { getBbMetadata } from "src/utils/asinMetadataUtils";
import { NativeAPI } from "src/utils/deviceUtils";

type PropTypes = {
    data?: QuickViewAsinMetadata;
    getter?: (asin: string) => Promise<QuickViewAsinMetadata>;
    dismiss?: () => void;
    includeFooter?: boolean;
    includeButtons?: boolean;
};

const maybeStopPropagation = (event: React.UIEvent) => {
    const ct = event.currentTarget;
    if (ct.scrollTop || ct.scrollHeight !== ct.clientHeight) {
        event.stopPropagation();
    }
};

const openKindleStoreTermsOfUse = () => NativeAPI.openWebPage('/gp/help/customer/display.html?nodeId=201014950');

const getLocalePriceString = (locale: string, price?: CurrencyInfo) => price?.amount?.toLocaleString(locale, { style: 'currency', currency: price.currency });

const getPriceData = (locale: string, domain: string, option?: AapiBuyingOption ) => {
    if (!option?.price) { return {}; }

    const priceGroup = option.price.entity;

    return {
        savingPrice: getLocalePriceString(locale, priceGroup?.savings?.money),
        savingsPercentage: priceGroup?.savings?.percentage?.displayString,
        points: option.points?.entity?.totalAmount?.points?.amount,
        basisPriceLabel: priceGroup?.basisPrice?.label,
        basisPriceString: getLocalePriceString(locale, priceGroup?.basisPrice?.moneyValueOrRange?.value),
        priceToPayLabel: priceGroup?.priceToPay?.label,
        priceToPayString: getLocalePriceString(locale, priceGroup?.priceToPay?.moneyValueOrRange?.value),
        shouldDisplayVATIncludedMessage: false,
        shouldDisplayVATEBookPBookDifferenceMessage: false,
        shouldDisplaySellerOfRecord: false,
        sellerOfRecord: option.merchant?.entity?.merchantName,
        shouldDisplayCommissionaireMessage: true,
        isAgencyWithTax: false,
    };
};

export const BuyBoxContent: React.FC<PropTypes> =  ({ data, getter, dismiss, includeFooter }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const asin = data?.asin ?? "";
    const metrics = newMetricsWithContext("BuyBox", asin);
    const [buyButtonEnabled, setBuyButtonEnabled] = useState(false);
    const [actionInProgress, setActionInProgress] = useState(false); // Used to prevent multiple taps triggering multiple AJAX calls
    const [addNarration, setAddNarration] = useState(false);
    const [hasFetchedMetadata, setHasFetchedMetadata] = useState(false);
    const [metadata, setMetadata] = useState(data);
    const fetchFailuresCount = useRef(0);
    const fetchFailureError = useRef("");
    const [alcBuyingOption, setAlcBuyingOption] = useState<AapiBuyingOption | undefined>(data?.primaryPurchaseBuyingOption)
    const waitStartedRef = useRef(Date.now());

    const actualGetter = getter || getBbMetadata;

    useEffect(() => {
        if (!hasFetchedMetadata) {
            debug.log(`Fetching buy box metadata for ${asin}`);
            setHasFetchedMetadata(true);
            actualGetter(asin).then((data) => {
                if (data.loadFailed) {
                    fetchFailureError.current = data.error || "unknown";
                    if (fetchFailuresCount.current === 0) {
                        metrics.recordOperationalMetric("fetchRetryWasTriggered", 1, fetchFailureError.current);
                    }
                    fetchFailuresCount.current += 1;
                    setHasFetchedMetadata(data.unrecoverableError === true);
                } else {
                    if (fetchFailuresCount.current > 0) {
                        metrics.recordOperationalMetric("fetchRetryWasSuccessful", 1, fetchFailureError.current);
                        metrics.recordOperationalMetric("fetchRetryWasSuccessfulAfterCount", fetchFailuresCount.current, fetchFailureError.current);
                    }
                    fetchFailuresCount.current = 0;
                }
                setMetadata((old) => {
                    return {...old, ...data };
                });
                setAlcBuyingOption(data.primaryPurchaseBuyingOption);
            });
        }
    }, [hasFetchedMetadata, getter, asin, metrics, actualGetter]);

    useEffect(() => {
        setBuyButtonEnabled(!!alcBuyingOption);
    }, [alcBuyingOption]);

    const isLongWait = () => {
        const elapsed = Date.now() - waitStartedRef.current;
        return (elapsed > 2500);
    };

    const getErrorMessage = () => {
        if (!metadata?.loadFailed) {
            return;
        }
        if (metadata.unrecoverableError) {
            return (
                <div className={styles.loadFailure}>
                    <p>
                        <i>&quot;Failure is the condiment that gives success its flavor.&quot;</i>
                        <br/>
                        <b>— Truman Capote</b>
                    </p>
                    <br/>
                    {translations.getText("unrecoverable-failure-message")}
                </div>
            );
        }
        if (navigator.onLine === false) {
            return (
                <div className={styles.loadFailure}>
                    {translations.getText("no-internet-connection-title")}
                    <br/>
                    <br/>
                    {translations.getText("no-internet-connection-message")}
                </div>
            );
        }
        if (isLongWait()) {
            return (
                <div className={styles.loadFailure}>
                    {translations.getText("long-request-message")}
                </div>
            );
        }
    };

    const handleTap = () => {
        if (actionInProgress) {
            return;
        }
        setActionInProgress(true);
    };

    const toggleAddNarration = () => {
        setAddNarration(!addNarration);
    };

    const offer = metadata?.primaryPurchaseBuyingOption;
    const isPreOrder = metadata?.canPreOrder && !metadata.canBuy;
    debug.log(`isPreOrder: ${isPreOrder}`);
    debug.log(offer);

    const priceIfOwnsCompanion = metadata?.whisperSyncForVoice?.companionBook?.priceIfOwnsCompanion;
    const addNarrationPrice = priceIfOwnsCompanion?.amount?.toLocaleString(deviceContext.locale, { style: 'currency', currency: priceIfOwnsCompanion.currency });

    const getPrimaryCtaText = () => {
        const oneClickDisplayString = metadata?.primaryPurchaseBuyingOption?.callToAction?.entity?.oneClick?.data?.displayString;
        if (oneClickDisplayString) {
            return oneClickDisplayString;
        }
        const oneClickPreorderDisplayString = metadata?.primaryPurchaseBuyingOption?.callToAction?.entity?.oneClickPreorder?.data?.displayString;
        if (oneClickPreorderDisplayString) {
            return oneClickPreorderDisplayString;
        }

        if (alcBuyingOption?.callToAction?.entity?.oneClick) { return translations.getText("buy-now"); }
        if (alcBuyingOption?.callToAction?.entity?.oneClickPreorder) {
            if (deviceContext.domain === "amazon.com") {
                return translations.getText("preorder-with-1-click");
            }
            return translations.getText("preorder-now");
        }
        return "";
    };

    const primaryCtaText = getPrimaryCtaText();

    const priceData = getPriceData(deviceContext.locale, deviceContext.domain, alcBuyingOption);
    debug.log(priceData);

    const isReady = metadata?.loaded === true;

    return (<>
    <main
        className={[styles.main, styles[deviceContext.theme]].join(" ")}
        onScroll={maybeStopPropagation}
        onTouchStart={maybeStopPropagation}
        onTouchMove={maybeStopPropagation}
        onTouchEnd={maybeStopPropagation}
        onTouchCancel={maybeStopPropagation}
    >
        {!isReady && (
            <div style={{ padding: "20px" }}>
                {metadata?.unrecoverableError !== true && (<Spinner />)}
                { getErrorMessage() }
                {(metadata?.loadFailed) && debug.get('enableLoadFailureMessages') && (
                    <div className={styles.loadFailure}>
                        { metadata.error }
                    </div>
                )}
            </div>
        )}

        {offer && (<>

            <div className={styles.buyBox}>
                <table className={styles.buyBoxTable}>
                    {priceData.basisPriceLabel && priceData.basisPriceString && (
                        <tr>
                            <td>{priceData.basisPriceLabel}</td>
                            <td>
                                <div className={styles.printListPrice}>
                                    {priceData.basisPriceString}
                                </div>
                            </td>
                        </tr>
                    )}
                    {priceData.priceToPayLabel && priceData.priceToPayString && (
                        <tr>
                            <td>{priceData.priceToPayLabel}</td>
                            <td>
                                <div className={styles.kindlePrice}>
                                    {priceData.priceToPayString}
                                </div>
                                {priceData.savingPrice && priceData.basisPriceString && (
                                    <div className={styles.savings}>
                                        Save {priceData.savingPrice} ({priceData.savingsPercentage})
                                    </div>
                                )}
                                {priceData.shouldDisplayVATIncludedMessage && (
                                    <div className={styles.vatIncludedMessage}>
                                        {translations.getText("vat-included-message")}
                                    </div>
                                )}
                            </td>
                        </tr>
                    )}
                    {priceData.points && (
                        <tr>
                            <td>{translations.getText("you-earn-label")}</td>
                            <td>
                                <div className={styles.points}>
                                    {translations.formatText("points-with-units", { points: priceData.points })}
                                </div>
                            </td>
                        </tr>
                    )}
                    {priceData.sellerOfRecord && (
                        <tr>
                            <td>{translations.getText("sold-by-label")}</td>
                            <td>
                                <div className={styles.sellerOfRecord}>
                                    {priceData.sellerOfRecord}
                                </div>
                                {priceData.shouldDisplayCommissionaireMessage && (
                                    <div className={styles.commissionaireMessage}>
                                        Price set by seller
                                    </div>
                                )}
                            </td>
                        </tr>
                    )}
                </table>
                {addNarrationPrice && (
                    <>
                        <table className={styles.addNarrationTable}>
                            <tr>
                                <td>
                                    <input type="checkbox" checked={addNarration} onClick={toggleAddNarration}/>
                                </td>
                                <td>
                                    <div className={styles.addNarrationSection}>
                                        Add an <img alt="" src="https://m.media-amazon.com/images/I/01ToaAyiR3L.svg" id="audible-logo"/> <span className={styles.activeText}> audiobook</span> with Audible narration for <span className={styles.addNarrationPrice}>{addNarrationPrice}</span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        {addNarration && (
                            <div className={styles.addNarrationLegalese}>
                                By purchasing this title, you agree to Audible&apos;s <span className={styles.activeText}>Conditions Of Use.</span>
                                <div>
                                    Sold and delivered by Audible, an Amazon company
                                </div>
                            </div>
                        )}
                    </>
                )}
                {isPreOrder && metadata.releaseDate && (
                    <div className={styles.preOrderAutoDeliverText}>
                        {interpolateTranslation(
                            translations.getText("preorder-auto-delivery-text"),
                            "releaseDate",
                            (<span className={styles.releaseDate}>{metadata.releaseDate?.displayString}</span>)
                        )}
                    </div>
                )}
            </div>
        </>) }

    </main>
    {includeFooter && (
        <footer className={styles.footer}>
            <div className={styles.footerButtons}>
                {primaryCtaText && (buyButtonEnabled && !actionInProgress
                    ? (<button className={styles.ctaButton} onClick={handleTap}>{primaryCtaText}</button>)
                    : (<button className={[styles.ctaButton, styles.disabledButton].join(" ")}>{primaryCtaText}</button>)
                )}
            </div>
            <div className={styles.oneClickLegalText}>
                <div>{interpolateTranslation(
                    translations.getText("add-to-your-library-for-free-legal-warning"),
                    "kindleStoreTermsOfUse",
                    <span className={styles.activeText} tabIndex={0} role="button" onClick={openKindleStoreTermsOfUse}>
                        {translations.getText("kindle-store-terms-of-use")}
                    </span>
                )}</div>
            </div>
        </footer>
    )}
    </>);
};

const BuyBox: React.FC<PropTypes> = ({ data, getter, dismiss }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const itemContainerClassName = `${styles.itemContainer} ${styles[deviceContext.theme]}`;

    return (
        <div className={itemContainerClassName}>
            <div className={styles.chevronIconContainer}>
                <div className={styles.chevronIcon} onClick={dismiss}/>
            </div>
            <BuyBoxContent data={data} getter={getter} dismiss={dismiss} includeFooter={true} />
        </div>
    );
};

export default BuyBox;
