export const THEME_QUERY_PARAM = 'theme';

export function setQueryParams(url: string, params?: { [index: string]: string }) {
    if (!params) {
        return url;
    }

    const defaultBaseUrl = window.location.origin;
    const urlObj = new URL(url, defaultBaseUrl);

    // Do not attempt to add query params to invalid URL types (such as "data:" protocol used in local dev)
    if (!urlObj.protocol?.startsWith('http')) {
        return url;
    }

    for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
            urlObj.searchParams.set(key, params[key]);
        }
    }
    return urlObj.href;
}

export function getCurrentBaseUrl(): string {
    if (location.protocol === "https:" && !location.hostname.endsWith("dev")) {
        return location.origin;
    }
    return `https://www.${window.quickViewData?.domain ? window.quickViewData.domain : "amazon.com"}`;
}

/**
 * URL(urlString) will throw a TypeError for relative URLs.
 */
export function isURLAbsolute(urlString: string): boolean {
    try {
        new URL(urlString);
        return true;
    } catch (e) {
        return false;
    }
}

export function getUrlHashParams() {
    return location.hash
        ?.slice(1) // Skip over '#' character
        .split("&")
        .reduce((parameters: { [key: string]: string }, parameter: string) => {
            const segments = parameter.split("=");
            if (segments.length === 2) {
                parameters[segments[0]] = segments[1];
            }
            return parameters;
        }, {}) ?? {};
}
