import React, { useContext } from "react";
import styles from "./StarRatingBar.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import { Theme } from "src/utils/themeUtils";
import TranslationsContext from "src/contexts/TranslationsContext";

const MAX_REVIEW_STARS = 5;

interface StarRatingBarProps {
    reviewsSummary?: ReviewsSummary;
    onTapCallback?: () => void;
}

const StarFull = () => (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.77675 6.72244L7.06814 6.69522L7.18709 6.42781L9.49906 1.23008L11.8119 6.42788L11.9309 6.69531L12.2224 6.72245L17.795 7.24141L13.5195 10.9868L13.2927 11.1854L13.3625 11.4786L14.6736 16.9891L9.75147 14.1574L9.50209 14.014L9.25274 14.1575L4.35606 16.9755L5.75802 11.4866L5.83503 11.1851L5.59852 10.9829L1.22299 7.2413L6.77675 6.72244Z" fill="#FFA41C" stroke="#DE7921"/>
    </svg>
);

const StarHalf = (props: { isDarkTheme: boolean }) => (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <g id="star" >
                <path d="M6.77675 6.72244L7.06814 6.69522L7.18709 6.42781L9.49906 1.23008L11.8119 6.42788L11.9309 6.69531L12.2224 6.72245L17.795 7.24141L13.5195 10.9868L13.2927 11.1854L13.3625 11.4786L14.6736 16.9891L9.75147 14.1574L9.50209 14.014L9.25274 14.1575L4.35606 16.9755L5.75802 11.4866L5.83503 11.1851L5.59852 10.9829L1.22299 7.2413L6.77675 6.72244Z" stroke="#DE7921"/>
            </g>
        </defs>
        <mask id="right_half" maskUnits="userSpaceOnUse" x="9" y="0" width="10" height="18">
            <path fillRule="evenodd" clipRule="evenodd" d="M19 0H9.4995V18H19V0Z" fill="white"/>
        </mask>
        <use xlinkHref="#star" fill="#FFA41C" />
        <use xlinkHref="#star" fill={props.isDarkTheme ? "#232323" : "white"} mask="url(#right_half)" />
    </svg>
);

const StarEmpty = (props: { isDarkTheme: boolean }) => (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.77675 6.72244L7.06814 6.69522L7.18709 6.42781L9.49906 1.23008L11.8119 6.42788L11.9309 6.69531L12.2224 6.72245L17.795 7.24141L13.5195 10.9868L13.2927 11.1854L13.3625 11.4786L14.6736 16.9891L9.75147 14.1574L9.50209 14.014L9.25274 14.1575L4.35606 16.9755L5.75802 11.4866L5.83503 11.1851L5.59852 10.9829L1.22299 7.2413L6.77675 6.72244Z" fill={props.isDarkTheme ? "#232323" : "white"} stroke="#DE7921"/>
    </svg>
);

export const StarRatingBar: React.FC<StarRatingBarProps> = ({ reviewsSummary, onTapCallback }) => {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const isDarkTheme = context.theme === Theme.DARK;
    const fullStars = reviewsSummary?.numberOfStars || 0;
    const halfStar = reviewsSummary?.hasHalfStar || false;
    const rating = fullStars + (halfStar ? 0.5 : 0);
    const starRatingsText = translations.formatText("star-rating-alt-text", {rating: rating.toLocaleString(context.locale)});
    const ratingsCountText = reviewsSummary?.numberOfReviews ? translations.formatText("ratings-count", {ratings: reviewsSummary?.numberOfReviews}) : "";

    const stars = [];
    let index = 0;
    while (index < fullStars) {
        stars[index++] = <StarFull />;
    }
    if (halfStar) {
        stars[index++] = <StarHalf isDarkTheme={isDarkTheme} />;
    }
    while (index < MAX_REVIEW_STARS) {
        stars[index++] = <StarEmpty isDarkTheme={isDarkTheme} />;
    }

    return (
        <div className={`${styles.starRatingBar} ${styles[context.theme]}`} onClick={onTapCallback} role={onTapCallback ? "button" : "presentation"} tabIndex={onTapCallback ? 0 : -1} aria-label={`${starRatingsText}. ${ratingsCountText}`}>
            <div className={styles.stars} role="img" aria-label={`${starRatingsText}. ${ratingsCountText}`}>
                {stars.map((item) => item)}
            </div>
            {reviewsSummary?.numberOfReviews && (
                <div className={styles.reviewCount}>{reviewsSummary?.numberOfReviews}</div>
            )}
        </div>
    );
};
