import React, { useCallback, useContext, useRef } from "react";
import styles from "./AddToListButton.module.scss";
import WorkflowContext from "src/contexts/WorkflowContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import DeviceContext from "src/contexts/DeviceContext";
import { recordBehavioralMetric } from "src/utils/metricsUtils";
import focusHelper from "src/utils/focusHelper";

type PropTypes = {
    asin: string;
};

const AddToListButton: React.FC<PropTypes> = ({ asin }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const workflowContext = useContext(WorkflowContext);
    const translations = useContext(TranslationsContext);
    const a11yTarget = useRef<HTMLButtonElement>(null);

    const requestA11yFocus = useCallback(() => {
        focusHelper.requestFocus(a11yTarget.current);
    }, [a11yTarget]);

    const addToList = () => {
        recordBehavioralMetric({namespace: "AddToListButton", qv_asin: asin}, "AddToList.click", 1);
        workflowContext.addToList(asin, requestA11yFocus);
    }

    return (
        <button type="button" className={[styles.addToListButton, styles[deviceContext.theme]].join(" ")} onClick={addToList} ref={a11yTarget}>
            {translations.getText("add-to-list")}
        </button>
    );
};

export default AddToListButton;
