import React, { useContext } from "react";
import { NativeAPI } from "src/utils/deviceUtils";
import TranslationsContext from "src/contexts/TranslationsContext";
import { interpolateTranslation } from "../Translations/Translations";
import styles from "./KuUpsell.module.scss";
import { newMetricsWithContext } from "src/utils/metricsUtils";
import { getProgramSticker } from "src/utils/programLogoUtils";
import DeviceContext from "src/contexts/DeviceContext";

type PropTypes = {
    item: QuickViewAsinMetadata;
    hasFullBook: boolean;
};


const KuUpsell: React.FC<PropTypes> = ({ item, hasFullBook }: PropTypes) => {
    const translations = useContext(TranslationsContext);
    const deviceContext = useContext(DeviceContext);
    const metrics = newMetricsWithContext("KuUpsell");

    if (!item.hasKuUpsell || hasFullBook || item.canBorrow) {
        return null;
    }

    const openKu = () => {
        metrics.recordBehavioralMetric(`KuTrial.click`, 1);
        NativeAPI.openWebPage(`/kindle-dbs/hz/subscribe/ku?passThroughAsin=${item.asin}`);
    };

    const kuLogo = getProgramSticker(deviceContext.theme, deviceContext.domain, item.kindleProgram);

    return (
        <div role="button" tabIndex={0} onClick={openKu} className={`${styles.kuUpsell} ${styles[deviceContext.theme]}`} >
            <div className={styles.upsellText}>
            {interpolateTranslation(
                translations.getText("ku-upsell-section-content"),
                "kindleUnlimitedLogo",
                (<span className={styles.kuLogo}>
                    <img className={styles.kuLogo} src={kuLogo?.src} alt={translations.getText(kuLogo?.altKey ?? "")} />
                </span>)
            )}
            </div>
            <div className={styles.chevronIconContainer}>
                <div className={styles.chevronIcon} />
            </div>


        </div>
    );
};

export default KuUpsell;
