import React, { useContext, useCallback } from "react";
import DeviceContext from "src/contexts/DeviceContext";
import { NativeAPI } from "src/utils/deviceUtils";
import { getCurrentBaseUrl } from "src/utils/urlUtils";
import styles from "./BookSeries.module.scss";
import { recordBehavioralMetric } from "src/utils/metricsUtils";

type PropTypes = {
    asin: string;
    seriesMetadata: SeriesMetadata;
};

const BookSeries: React.FC<PropTypes> = ({ asin, seriesMetadata }) => {
    const context = useContext(DeviceContext);

    const handleTap = useCallback(() => {
        recordBehavioralMetric({ namespace: "SeriesLink", qv_asin: asin }, "SeriesLink.Click", 1);
        NativeAPI.openWebPage(`${getCurrentBaseUrl()}${seriesMetadata.url}`);
    }, [asin, seriesMetadata.url]);

    return (
        <div
            className={`${styles.bookSeries} ${styles[context.theme]} ${styles.miniWidget}`}
            role="button"
            tabIndex={0}
            onClick={handleTap}
        >
            <div className={styles.link}>{seriesMetadata.message}</div>
        </div>
    );
};

export default BookSeries;
