import React from "react";
import debug from "src/utils/debugUtils";
import { NativeAPI } from "src/utils/deviceUtils";
import { flushBatchedMetrics, newMetricsWithContext } from "src/utils/metricsUtils";

type PropTypes = {
    children?: React.ReactNode;
};

type State = {
    hasError: boolean;
    errorInfo?: React.ErrorInfo;
    errorMessage?: string;
};

const metrics = newMetricsWithContext("ErrorBoundary");

class ErrorBoundary extends React.Component<PropTypes, State> {

    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError = (error: Error): State => ({
        hasError: true,
        errorMessage: error.message,
    });

    public componentDidCatch = (error: Error, errorInfo: React.ErrorInfo) => {
        metrics.recordOperationalMetric(`Error`, 1, error.message); 
        flushBatchedMetrics();
        this.setState({
            errorInfo: errorInfo,
            errorMessage: error.message,
        });
    };

    public render() {
        if (this.state.hasError) {
            if (!debug.get("enableVisibleErrorBoundary")) {
                // Try to nope out, which will open DP instead
                if (!NativeAPI.didFail(this.state.errorMessage)) {
                    // didFail API isn't present (e.g., Android), so just dismiss QV manually
                    NativeAPI.dismissQuickView();
                }
                // Webview should be on its way to being closed automatically at this point, but add a way to dismiss it just in case
                return (<div
                    style={{
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        color: "transparent",
                        backgroundColor: "transparent",
                        padding: "12px",
                        fontSize: "12px",
                    }}
                    onClick={() => NativeAPI.dismissQuickView()}></div>);
            }

            return (
                <div
                    style={{
                        marginTop: "env(safe-area-inset-top)",
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        color: "black",
                        backgroundColor: "white",
                        padding: "12px",
                        fontSize: "12px",
                    }}
                    onClick={() => NativeAPI.dismissQuickView()}
                >
                    <h1>Tap to dismiss.</h1>
                    <hr />
                    <h2>Please screenshot and submit to #quickview-kindle-lcd-beta-feedback on Slack</h2>
                    <div>
                        {debug.versionInfo.buildDate && new Date(debug.versionInfo.buildDate).toString()}
                        <pre>
                            {JSON.stringify(debug.versionInfo, null, 4)}
                        </pre>
                        <div style={{fontSize: "10px"}}>
                            {navigator.userAgent}
                        </div>
                    </div>
                    <h3>Error:</h3>
                    <p>{this.state.errorMessage}</p>
                    <pre>{this.state.errorInfo?.componentStack}</pre>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
