import React from "react";
import DeviceContext from "src/contexts/DeviceContext";
import BottomSheet, { BottomSheetState } from "src/components/BottomSheet/BottomSheet";
import { NativeAPI } from "src/utils/deviceUtils";
import { getBbMetadata } from "src/utils/asinMetadataUtils";
import WorkflowContext from "src/contexts/WorkflowContext";
import BuyBox from "../BuyBox/BuyBox"
import ChangeoverToast from "../ChangeoverToast/ChangeoverToast";
import DebugGooey from "../DebugGooey/DebugGooey";
import { disableMetrics, flushBatchedMetrics } from "src/utils/metricsUtils";
import { getUrlHashParams } from "src/utils/urlUtils";

type PropTypes = object;

type State = {
    data: QuickViewAsinMetadata;
    bottomSheetState: BottomSheetState;
    subBottomSheetDismissCallback?: VoidFunction;
    infoMessage?: string;
    showDebugGooey?: boolean;
    isBottomSheetDragging: boolean;
    hasOpened: boolean;
};

class BuyBoxOnly extends React.PureComponent<PropTypes, State> {
    static contextType = DeviceContext;
    declare context: React.ContextType<typeof DeviceContext>;

    scrollerDiv: React.RefObject<HTMLUListElement>;
    isFetching = false;
    shouldSyncLibraryOnDismiss = false;

    constructor(props: PropTypes) {
        super(props);
        this.state = this.createInitialState();
        this.scrollerDiv = React.createRef<HTMLUListElement>();
        this.isFetching = false;
    }

    /**
     * Allow initial state values to be pre-populated from `window.quickViewData` (preferred) and/or the URL hash params.
     * On Android, if the window.quickViewData object isn't present, self-inject it via a call to `window.QuickView.getQuickViewData`.
     * If no (valid) URL hash or injected metadata present, returns `State` with default parameter values.
     */
    createInitialState(): State {
        const urlHashParams = getUrlHashParams();
        const injectedAsinMetadata = window.quickViewData?.asins;
        const hashParamAsinMetadata: QuickViewAsinMetadata[] = urlHashParams.asins?.split(",").map((asin) => {
            return {
                asin: asin,
            };
        });

        const asinsMetadata = injectedAsinMetadata ?? hashParamAsinMetadata;
        const injectedInitialIndex = window.quickViewData?.initialIndex;
        const hashParamInitialIndex = Number(urlHashParams.initialIndex);
        const initialIndex: number = (injectedInitialIndex ?? hashParamInitialIndex) || 0;

        const data = asinsMetadata?.[initialIndex];
        // We're in the cache warmer state, don't record any more metrics
        if (!data || !data.asin) {
            disableMetrics();
        }
        // TODO: handle incorrect open

        return {
            bottomSheetState: data?.asin ? BottomSheetState.FULLSCREEN : BottomSheetState.DISMISSED,
            isBottomSheetDragging: false,
            hasOpened: false,
            data: data,
        };
    }

    afterOpen = () => {
        window.onBackPressed = () => {
            this.dismissBottomSheet();
            return true;
        }
        this.setState({
            hasOpened: true,
        });
    }

    dismissBottomSheet = () => {
        this.setState({
            bottomSheetState: BottomSheetState.DISMISSED,
        });
    };

    handleClose = () => {
        if (this.shouldSyncLibraryOnDismiss) {
            NativeAPI.syncLibrary();
        }
        flushBatchedMetrics();
        // TODO: Update to use a different dismiss
        NativeAPI.dismissQuickView();
    };

    handleBottomSheetDismiss = () => {
        this.handleClose()
    };

    setInfoMessage = (data: string) => this.setState({ infoMessage: data });

    clearInfoMessage = () => this.setState({ infoMessage: undefined });

    showDebugGooey = () => this.setState({ showDebugGooey: true });

    hideDebugGooey = () => this.setState({ showDebugGooey: false });

    startBottomSheetDragging = () => this.setState({ isBottomSheetDragging: true });

    endBottomSheetDragging = () => this.setState({ isBottomSheetDragging: false });

    shouldSyncLibraryCallback = () => this.shouldSyncLibraryOnDismiss = true;

    render() {
        const noOp = () => { /* no op */ };
        return (
            <WorkflowContext.Provider
                value={{
                    addToList: noOp,
                    showBuyBox: noOp,
                    borrowLimit: noOp,
                    infoMessage: this.setInfoMessage,
                    showDebugGooey: this.showDebugGooey,
                    openQv: noOp,
                }}
            >
                {this.state.data && (
                    <BottomSheet state={BottomSheetState.FULLSCREEN} afterDismiss={this.handleBottomSheetDismiss}>
                        <BuyBox data={this.state.data} getter={getBbMetadata} dismiss={this.handleBottomSheetDismiss} />
                    </BottomSheet>
                )}
                {this.state.infoMessage && (
                    <ChangeoverToast message={this.state.infoMessage} dismiss={this.clearInfoMessage} />
                )}
                {this.state.showDebugGooey && (
                    <BottomSheet state={BottomSheetState.FULLSCREEN} afterDismiss={this.hideDebugGooey}>
                        <DebugGooey dismiss={this.hideDebugGooey} mode="buyBox" />
                    </BottomSheet>
                )}
            </WorkflowContext.Provider>
        );
    }
}

export default BuyBoxOnly;
