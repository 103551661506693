import React, { useContext, useState } from "react";
import styles from "./DebugGooey.module.scss";
import debug from "src/utils/debugUtils";
import DeviceContext from "src/contexts/DeviceContext";
import { getCurrentLocale } from "src/utils/localeUtils";
import { fetchYourBooksCsrfToken } from "src/utils/ajaxUtils";

type PropTypes = {
    dismiss: () => void;
    mode?: string;
};

type ToggleProps = {
    callback: (state: boolean) => void;
    initialState: boolean;
};

type OptionProps = {
    label: string;
    storeKey: string;
};

type ActionProps = {
    label: string;
    action: () => void;
}

type SectionLabelProps = {
    label: string;
};

const DebugToggle: React.FC<ToggleProps> = ({ callback, initialState }: ToggleProps) => {
    const [isSelected, setSelected] = useState(initialState);

    const toggle = () => {
        const selected = !isSelected;
        setSelected(selected);
        callback(selected);
    };

    return (
        <div className={`${styles.toggle} ${isSelected ? styles.checked : ""}`} onClick={toggle} role="switch" tabIndex={0}>
            <svg viewBox="0 0 24 16">
                <rect x="0" y="0" width="24" height="16" rx="8" ry="8"></rect>
                <circle className={styles.toggleHandle} cy="8" cx={8 + (isSelected ? 8 : 0)} r="6"></circle>
            </svg>
        </div>
    );
};

const Option: React.FC<OptionProps> = ({ label, storeKey }: OptionProps) => {
    const initialState = debug.get(storeKey);
    const callback = (state: boolean) => debug.set(storeKey, state);
    return (
        <section className={styles.option}>
            <label>{label}</label>
            <DebugToggle callback={callback} initialState={initialState} />
        </section>
    );
};

const ActionButton: React.FC<ActionProps> = ({ label, action }: ActionProps) => {
    return (
        <section className={styles.action}>
            <button onClick={action}>{label}</button>
        </section>
    );
};

const SectionLabel: React.FC<SectionLabelProps> = ({ label }: SectionLabelProps) => {
    return (
        <section className={styles.sectionLabel}>
            <label>{label}</label>
        </section>
    );
};

const fetchCsrfToken = async () => {
    const csrfToken = await fetchYourBooksCsrfToken();
    debug.setString("datamateCsrfToken", csrfToken);
};

const DebugGooey: React.FC<PropTypes> = ({ dismiss, mode }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const [showErrorLogs, setShowErrorLogs] = useState(false);
    const [showVersionInfo, setShowVersionInfo] = useState(false);

    const fittedContainerClassNames = `${styles.fittedContainer} ${styles[deviceContext.theme]}`;

    const stopPropagation = (event: React.UIEvent) => {
        event.stopPropagation();
    };

    return (
        <div
            className={fittedContainerClassNames}
            onScroll={stopPropagation}
            onTouchStart={stopPropagation}
            onTouchMove={stopPropagation}
            onTouchEnd={stopPropagation}
            onTouchCancel={stopPropagation}
        >
            <header>
                Super Secret Debug Gooey
                <div className={styles.dismissButton} onClick={dismiss} role="button" tabIndex={0}>
                    &#10005;
                </div>
            </header>
            <main>
                { showErrorLogs ? (<>
                    <SectionLabel label="Error logs:" />
                    <div className={styles.errorLogs}>
                        {debug.errorLogs.map((v, i) => (<div className={styles.errorLog} key={i}>{v}</div>))}
                    </div>
                </>)
                : (<>

                    <SectionLabel label="VersionInfo:" />
                    <div onClick={() => setShowVersionInfo(!showVersionInfo)}>
                        {debug.versionInfo.buildDate && new Date(debug.versionInfo.buildDate).toString()}
                        <pre className={showVersionInfo ? "" : styles.hideVersionInfo}>
                            {JSON.stringify(debug.versionInfo, null, 4)}
                        </pre>
                        <div className={showVersionInfo ? "" : styles.hideVersionInfo}>
                            {navigator.userAgent}
                        </div>
                        <div className={showVersionInfo ? "" : styles.hideVersionInfo}>
                            Locale: {getCurrentLocale()}
                        </div>
                    </div>


{mode === "buyBox"
    ? (<>
                    <SectionLabel label="Buy box bonanza:" />
                    <Option label="Disable loads" storeKey="disableBbMetadataLoads" />
                    <Option label="Disable BiFrost loads" storeKey="disableBiFrostLoads" />
                    <Option label="Enable load failure messages in UI" storeKey="enableLoadFailureMessages" />
                    <Option label="Disable metrics" storeKey="disableMetrics"/>
    </>)
    : (<>
                    <SectionLabel label="Opt into debug UI in prod (this will go away soon):" />
                    <Option label="Enable debug UI in prod" storeKey="enableDebugGooey" />

                    <SectionLabel label="More like this:" />
                    <Option label="Enable 'More like this' recommendations (requires card reload)" storeKey="enableMoreLikeThis" />
                    <Option label="Enable new webview on rec tap" storeKey="enableMoreLikeThisInNewWebview" />
                    <Option label="Disable 'More like this' data loads" storeKey="disableMoreLikeThisLoads" />
                    <ActionButton label="Fetch new CSRF token" action={fetchCsrfToken} />

                    <SectionLabel label="Experimental UX:" />
                    <Option label="Enable post-purchase message on pre-orders" storeKey="enablePostPurchaseMessageOnPreOrders" />
                    <Option label="Enable KU upsell UX" storeKey="enableKuUpsell" />
                    <Option label="Force 'Add to your library for free' CTA regardless of marketplace" storeKey="forceAddToYourLibraryForFree" />
                    <Option label="Enable purchase flow CX (USAmazon Android)" storeKey="enablePurchaseFlowCX" />
                    <Option label="Force purchase flow CX elgibility" storeKey="forcePurchaseFlowEligible" />

                    <SectionLabel label="Developer focused things (used for actual debugging):" />
                    <Option label="Enable debug logs (requires reload)" storeKey="enableDebugLogs" />
                    <Option label="Enable error logs (requires reload)" storeKey="enableErrorLogs" />
                    <Option label="Enable load failure messages in UI" storeKey="enableLoadFailureMessages" />
                    <Option label="Enable new AAPIBB loads" storeKey="enableNewDataApi" />
                    <Option label="Disable AAPI loads" storeKey="disableQvMetadataLoads" />
                    <Option label="Disable Vella loads" storeKey="disableVellaDataLoads" />
                    <Option label="Disable BiFrost loads" storeKey="disableBiFrostLoads" />
                    <Option label="Disable Customer Review loads" storeKey="disableCustomerReviewLoads"/>
                    <Option label="Disable metrics" storeKey="disableMetrics"/>
                    <Option label="Disable GetBookStatus" storeKey="disableGetBookStatus"/>
                    <Option label="Disable Android scroll fixup" storeKey="disableAndroidScrollFixup" />
                    <Option label="Disable batch AAPI loads" storeKey="disableBatchMetadataLoads" />
                    <Option label="Force share button" storeKey="alwaysShowShare" />
                    <Option label="Force return and borrow flow on borrow attempt" storeKey="forceReturnAndBorrowFlow" />
                    <Option label="Force post-purchase message on pre-orders" storeKey="forcePostPurchaseMessageOnPreOrders" />
                    <Option label="Force RPL (requires reload of ASIN data)" storeKey="forceRpl" />
                    <Option label="Disable cover loads" storeKey="disableCoverLoads" />
                    <Option label="Delay cover loads" storeKey="delayCoverLoads" />
                    <Option label="Limit to 1 card (requires reload)" storeKey="limitToOneCard" />
                    <Option label="Limit to 3 cards (requires reload)" storeKey="limitToThreeCards" />
                    <Option label="Enable Bottom Sheet drag smoothing (requires reload)" storeKey="smoothBottomSheetDragging" />
                    <Option label="Delay loading Customer Reviews until they are visible" storeKey="delayLoadingCustomerReviewsUntilVisible" />

                    <SectionLabel label="QuickView Parameters:" />
                    {location.hash &&
                        <div className={styles.parameters}>
                            URL hash params:
                            <pre>{location.hash}</pre>
                        </div>
                    }
                    {window.quickViewData?.initialIndex != null &&
                        <div className={styles.parameters}>
                            <pre>{JSON.stringify(window.quickViewData, null, 4)}</pre>
                        </div>
                    }
    </>)}
                </>)}
            </main>
            <footer>
                <button onClick={() => setShowErrorLogs(!showErrorLogs)}>Error logs ({debug.errorLogs.length})</button>
                <button onClick={dismiss}>Mischief managed</button>
            </footer>
        </div>
    );
};

export default DebugGooey;
