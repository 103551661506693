import React, { useContext } from "react";
import styles from "./FollowStoryCard.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import FollowStoryButton from "./FollowStoryButton";
import BookCoverWithPlaceholder from "../BookCoverWithPlaceholder/BookCoverWithPlaceholder";

interface PropTypes {
    metadata: QuickViewAsinMetadata;
}

const FollowStoryCard: React.FC<PropTypes> = ({ metadata }: PropTypes) => {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const vella = metadata.vellaData;

    if (!vella) return null;

    return (<section className={`${styles.section} ${styles[context.theme]}}`}>
        <div className={styles.sectionTitle}>
            {translations.getText("vella-follow-story-card-label")}
        </div>
        <div className={styles.content}>
            <div className={styles.row}>
                <div className={styles.coverImageWrapper}>
                    <BookCoverWithPlaceholder
                        asin={metadata.asin}
                        authors={metadata.authors}
                        title={metadata.title ?? ""}
                        physicalId={metadata.physicalId}
                        imagePxSize={200}
                        imagePxType="width"
                        isVella={metadata.isShortStory}
                    />
                </div>
                <div className={styles.description}>
                    <div>{translations.getText("vella-follow-story-card-description")}</div>
                </div>

            </div>
            <div className={styles.buttonWrapper}>
                <FollowStoryButton asin={vella.asin || ""} />
            </div>
        </div>
    </section>);
};

export default FollowStoryCard;
