import React from "react";

export const VellaFooterChevron = () => (
    <svg width="6px" height="10px" viewBox="0 0 6 10" version="1.1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" aria-label="navigate">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(3, 5) rotate(-90) translate(-12, -12)">
                <path fill="currentColor" d="M15.1384701,9.71092634 L11.9998646,12.5523788 L8.86125903,9.71092634 C8.52334347,9.40428279 8.00624266,9.43594707 7.70678238,9.78008779 C7.4065039,10.1242285 7.43677721,10.6508555 7.77469277,10.9566658 L11.4565815,14.2897479 C11.6112208,14.4305706 11.8059518,14.5005653 11.9998646,14.5005653 C12.1937774,14.5005653 12.3885084,14.4305706 12.5431477,14.2897479 L16.2250364,10.9566658 C16.562952,10.6508555 16.5932253,10.1242285 16.2929468,9.78008779 C15.9926683,9.43594707 15.4755675,9.40511606 15.1384701,9.71092634 Z"></path>
            </g>
        </g>
    </svg>
);
