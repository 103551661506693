import { getStoreWebviewParameters, NativeAPI, isIOSDevice } from './deviceUtils';
import { THEME_QUERY_PARAM } from './urlUtils';

export enum Theme {
    LIGHT = 'light',
    DARK = 'dark',
}

export const DEFAULT_THEME = Theme.LIGHT;
export const DEFAULT_FONT_SCALE = 1.0;
export const DEVICE_DARK_THEME_SUPPORT_MEDIAQUERY = '(prefers-color-scheme: dark)';
export const DEVICE_LIGHT_THEME_SUPPORT_MEDIAQUERY = '(prefers-color-scheme: light)';

/**
 * Check whether this device supports retrieving the theme dynamically via CSS @media query syntax.
 * This works much better than the previous URL query param or `x-amzn-kstore` cookie approaches as
 * it allows updates without a page reload. However, this does not work if the app theme is configurable
 * separately from the device theme, like was the case on pre-2021 Android/iOS app builds and (as of early
 * 2022) still applies to FOS devices. This also doesn't appear to currently work on Android devices who
 * are always returning "light" theme for the "prefers-color-scheme" @media query. This may require app
 * changes to allow it to take the true device value.
 *
 * @returns true if we can rely on CSS media query based device theming
 */
export function supportsDeviceTheming(): boolean {
    return isIOSDevice();
}

function getThemeNameFromDevice(): string | undefined {
    return window.matchMedia(DEVICE_DARK_THEME_SUPPORT_MEDIAQUERY).matches
        ? Theme.DARK
        : window.matchMedia(DEVICE_LIGHT_THEME_SUPPORT_MEDIAQUERY).matches
        ? Theme.LIGHT
        : undefined;
}

function isValidTheme(theme: string): boolean {
    return Object.values(Theme).includes(theme as Theme);
}

function getThemeNameFromKstoreCookie(): string | undefined {
    const theme = getStoreWebviewParameters()?.theme ?? '';
    return isValidTheme(theme) ? theme : undefined;
}

function getThemeNameFromQueryParam(): string | undefined {
    const queryParams = new URLSearchParams(window.location.search);
    const theme = queryParams.get(THEME_QUERY_PARAM) ?? '';
    return isValidTheme(theme) ? theme : undefined;
}

export function getThemeName(): string {
    const providedValue =
        // Prioritize theming using CSS @media queries first as they update fastest (no page reload required)
        (
            (supportsDeviceTheming() ? getThemeNameFromDevice() : undefined) ||
            NativeAPI.getThemeName() ||
            getThemeNameFromQueryParam() ||
            getThemeNameFromKstoreCookie() ||
            DEFAULT_THEME
        ).toLowerCase();

    return isValidTheme(providedValue) ? providedValue : DEFAULT_THEME;
}
