import React from "react";
import { render } from "preact";
import App from "./components/App";
import "src/styles/global.scss";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

const startTime = Date.now();
window.qv = window.qv || {};
window.qv.jsStartTime = startTime;

const Index = (
    <React.StrictMode>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </React.StrictMode>
);

render(Index, document.getElementById("root")!);
