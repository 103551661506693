import React, { useContext } from "react";
import styles from "./ContinueHereCard.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import { VellaFooterChevron } from "./VellaFooterChevron";
import BorrowManagerContext from "src/contexts/BorrowManagerContext";


interface PropTypes {
    vella: VellaProduct;
}

const ContinueHereCard: React.FC<PropTypes> = ({ vella }: PropTypes) => {
    const context = useContext(DeviceContext);
    const borrowManagerContext = useContext(BorrowManagerContext);
    const translations = useContext(TranslationsContext);

    if (!vella || !vella.nextEpisode) return null;

    const openEpisode = () => {
        borrowManagerContext.openVellaEpisode(vella.nextEpisode);
    };

    return (<section className={`${styles.continueHere} ${styles[context.theme]}`}>
        <div className={`${styles.sectionTitle}`}>
            {translations.getText("vella-continue-here-label")}
        </div>

        <div className={styles.content}>
            <div className={styles.numberCircle}>
                <div className={styles.header}>{translations.getText("vella-episode-label")}</div>
                <div className={styles.number}>{vella.nextEpisode.episodeNumber}</div>
            </div>
            <div className={styles.metadata}>
                <div className={styles.title}>{vella.nextEpisode?.title}</div>
                <div className={styles.publishDate}>
                    {translations.formatText("vella-publish-date", { publishDate: vella.nextEpisode?.publicationDate })}
                </div>
            </div>
        </div>

        <hr className={styles.contentDivider} aria-hidden/>
        <div className={styles.readEpisode} onClick={ openEpisode } role="button" tabIndex={0}>
        {translations.formatText("vella-read-episode-number", {episodeNumber: vella.nextEpisode?.episodeNumber || 0 + 1})}
            <VellaFooterChevron/>
        </div>
    </section>);
};

export default ContinueHereCard;
