import React from "react";
import QuickView from "./QuickView/QuickView";
import DeviceContext, { DeviceContextI } from "src/contexts/DeviceContext";
import { getThemeName, Theme } from "src/utils/themeUtils";
import { getCurrentLocale } from "src/utils/localeUtils";
import { getDeviceDetails, SURFACE_TYPE } from "src/utils/deviceUtils";
import Translations from "./Translations/Translations";
import debug from "src/utils/debugUtils";
import { disableMetrics, setQvSessionId } from "src/utils/metricsUtils";
import { setRequestDomain, setUseRequestDomainOnRequests } from "src/utils/ajaxUtils";
import { getSurfaceType } from "src/utils/deviceUtils"
import { uuidv4 } from "src/utils/uuid";
import { getUrlHashParams } from "src/utils/urlUtils";
import BuyBoxOnly from "./QuickView/BuyBoxOnly";

type PropTypes = object;

let localeChangeCallback: (locale: string) => void | undefined;
const onLocaleChange = (callback: (locale: string) => void) => {
    localeChangeCallback = callback;
};

window.addEventListener("languagechange", () => {
    if (typeof localeChangeCallback === "function") {
        localeChangeCallback(navigator.language);
    }
});

let themeChangeCallback: (theme: Theme) => void | undefined;
const onThemeChange = (callback: (theme: Theme) => void) => {
    themeChangeCallback = callback;
};

window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
    if (typeof themeChangeCallback === "function") {
        const theme = window.matchMedia("(prefers-color-scheme: dark)").matches ? Theme.DARK : Theme.LIGHT;
        themeChangeCallback(theme);
    }
});

let visibilityChangedCallback: (focused: boolean) => void | undefined;
const onVisibilityChange = (callback: (isVisible: boolean) => void) => {
    visibilityChangedCallback = callback;
};

window.addEventListener("visibilitychange", () => {
    if (typeof visibilityChangedCallback === "function") {
        visibilityChangedCallback(document.visibilityState === 'visible');
    }
});

const purchaseFlowEligibleSurfaceTypes = [
    SURFACE_TYPE.androidMobile,
    SURFACE_TYPE.androidTablet,
];

const purchaseFlowElgibleDomains = [
    "amazon.com",
];

const getDomain = () => {
    const domain = window.quickViewData?.domain || window.location.host.replace(/.*amazon/i, "amazon");
    if (domain.startsWith("amazon.") && !domain.endsWith("dev")) {
        return domain.toLowerCase();
    } else {
        return "amazon.com";
    }
};

const mode = getUrlHashParams().mode; // ?? "buyBox";

export class App extends React.Component<PropTypes, DeviceContextI> {
    constructor(props: PropTypes) {
        super(props);

        if (!window.quickViewData && window.QuickView?.getQuickViewData) {
            window.quickViewData = JSON.parse(window.QuickView.getQuickViewData());
        }

        // TODO: access quickViewData.experimentalFeatures here
        const fullUrlsSupported = window.quickViewData?.experimentalFeatureMap?.aapiAjaxUrlsEnabled === true
                               || window.quickViewData?.experimentalFeatures?.aapiAjaxUrlsEnabled === true;
        setUseRequestDomainOnRequests(fullUrlsSupported);

        if (debug.get("disableMetrics")) {
            disableMetrics();
        }

        const domain = getDomain();
        setRequestDomain(domain);
        setQvSessionId(`${getSurfaceType()}.${debug.versionInfo.buildDate}.${debug.versionInfo.mainJs}.${domain}.${uuidv4()}`);

        const deviceDetails = getDeviceDetails();
        const isPurchaseFlowEligible = debug.get("enablePurchaseFlowCX") &&  purchaseFlowElgibleDomains.includes(domain) && purchaseFlowEligibleSurfaceTypes.includes(deviceDetails.surfaceType);

        this.state = {
            theme: getThemeName() as Theme,
            locale: getCurrentLocale(),
            device: deviceDetails,
            domain: domain,
            isVisible: document.visibilityState === 'visible',
            isFullUrlSupported: fullUrlsSupported,
            isPurchaseFlowEligible: isPurchaseFlowEligible || debug.get("forcePurchaseFlowEligible"),
        };
        onLocaleChange(this.onLocaleChangeCallback);
        onThemeChange(this.onThemeChangeCallback);
        onVisibilityChange(this.onVisibilityChangeCallback);
    }

    onLocaleChangeCallback = (locale: string) => {
        if (locale && locale !== this.state.locale) {
            this.setState({ locale });
        }
    };

    onThemeChangeCallback = (theme: Theme) => {
        if (theme !== this.state.theme) {
            this.setState({ theme });
        }
    };

    onVisibilityChangeCallback = (isVisible: boolean) => {
        if (isVisible !== this.state.isVisible) {
            this.setState({ isVisible });
        }
    };

    render() {
        return (
            <DeviceContext.Provider value={this.state}>
                <Translations locale={this.state.locale}>
                    {mode === "buyBox"
                        ? (<BuyBoxOnly />)
                        : (<QuickView />)
                    }
                </Translations>
            </DeviceContext.Provider>
        );
    }
}

export default App;
