import React from "react";

export interface TranslationsContextI {
    getText: (messageKey: string) => string;
    formatText: (messageKey: string, args: Record<string, unknown>) => string;
    isBundleLoaded: boolean;
}

const TranslationsContext = React.createContext<TranslationsContextI>({
    getText: () => "",
    formatText: () => "",
    isBundleLoaded: false,
});

export default TranslationsContext;
