import { asLegacyOverview, asLegacySeries, canBuyForFree, getByLine, hasBorrowCta, hasKuUpsell, hasLimberCta, hasPreOrderCta, hasPurchaseCta, hasReadNowCta, hasSampleCta, isRpl, primaryPurchaseCta } from "./aapiResponseUtils";
import { AsinOffers, fetchAsinMetadataWithPerf, fetchAsinOffers, fetchVellaProduct, fetchVellaLikeCount, perfToString, fetchVellaEpisodes, fetchVellaStoryFollowInfo, fetchAsinRecsFromDatamate, PerfWrapper, fetchAsinMetadataAapiWithPerf } from "./ajaxUtils";
import debug from "./debugUtils";
import { NativeAPI } from "./deviceUtils";
import { programCodeFromSticker } from "./programLogoUtils";

export type BookOfferStatus = {
    hasSample: boolean;
    canBorrow: boolean;
};

const activeFetches = new Map<string, Promise<any>>();
const asinMetadataMap = new Map<string, QuickViewAsinMetadata>();
const asinRecsMap = new Map<string, RecsResponse>();
const vellaDataMap = new Map<string, VellaProduct>();
const offersMap = new Map<string, Promise<PersonalizedAction[]>>();
const asinLoadFailures = new Map<string, number>();

const delayPromise = (ms: number) => new Promise((r) => setTimeout(r, ms));
const withRetries = <Type>(f: () => Promise<Type>, delay = 500, retries = 5): Promise<Type> =>
    new Promise<Type>((resolve, reject) =>
        f()
            .then(resolve)
            .catch((reason) => {
                if (retries > 0) {
                    return delayPromise(delay)
                        .then(() => withRetries(f, delay + 100, retries - 1))
                        .then(resolve)
                        .catch(reject);
                }
                return reject(reason);
            })
    );

const getReleaseDate = (item : PicassoAsinMetadata) => {
    const date = Date.parse(
        item.releaseDate?.iso8601String ||
        item.releaseDate?.displayString ||
        item.overview?.find(md => md.labelId === "book_details-publication_date")?.value?.fragments?.[0]?.text ||
        ""
    );

    if (isNaN(date)) {
        return undefined;
    }

    const releaseDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return {
        date: releaseDate,
        displayString: item.releaseDate?.displayString,
        isPrerelease: releaseDate >= today
    };
}

const isValidCtaOfType = (offer: CallToAction, actionType: string) => {
    const isCtaOfType = offer.actionType === actionType;
    if (isCtaOfType && actionType === "Borrow") {
        // Cannot borrow Kindle Freetime Unlimited (KFTU) books via BiFrost
        return offer.actionProgramCode !== "KFTU" && offer.actionProgramCode !== "KINDLE_FREETIME";
    }
    return isCtaOfType;
};

const isUnconditionalCtaOfType = (offer: CallToAction, actionType: string) => {
    return isValidCtaOfType(offer, actionType) && !offer.conditional;
};

const isKuUpsell = (offer: CallToAction) => {
    return isValidCtaOfType(offer, "Borrow") && offer.conditional && offer.actionProgramCode === "KINDLE_UNLIMITED";
};

const convertAapiItem = (item: AapiAsinMetadata): QuickViewAsinMetadata => {
    debug.log(item);

    const newItem: QuickViewAsinMetadata = {
        asin: item.asin,
        physicalId: item.productImages?.images?.[0]?.hiRes?.physicalId || item.productImages?.images?.[0]?.lowRes?.physicalId,
        title: item.title,
        authors: getByLine(item.byLine?.contributors),
        description: item.description?.content,
        overview: asLegacyOverview(item),
        reviewsSummary: {
            numberOfStars: item.customerReviewsSummary?.rating?.fullStarCount,
            numberOfReviews: item.customerReviewsSummary?.count?.displayString,
            hasHalfStar: item.customerReviewsSummary?.rating?.hasHalfStar,
        },
        series: asLegacySeries(item),
        audibleUrl: item.audible?.audioSample?.url,
        audibleDisplayText: item.audible?.audioSample?.displayText,
        isTandem: !!item.audible?.tandemNotification,
        kindleProgram: { stickerStyleCode: item.kindleProgramLowCost?.stickerStyleCode, physicalId: item.kindleProgramLowCost?.image?.physicalId },
        sellerOfRecord: item.buyingOptions?.[0].merchant?.entity?.merchantName,
        hasSample: hasSampleCta(item.buyingOptions),
        canBorrow: hasBorrowCta(item.buyingOptions),
        canBuy: hasPurchaseCta(item.buyingOptions),
        canReadNow: hasReadNowCta(item.buyingOptions),
        canBuyForFree: canBuyForFree(item.buyingOptions),
        canPreOrder: hasPreOrderCta(item.buyingOptions),
        primaryPurchaseBuyingOption: primaryPurchaseCta(item.buyingOptions),
        allBuyingOptions: item.buyingOptions,
        hasKuUpsell: hasKuUpsell(item.buyingOptions),
        isRPL: isRpl(item.kindleProgramLowCost),
        isAFR: hasLimberCta(item.buyingOptions),
        isShortStory: item.productCategory?.glProductGroup?.symbol === "gl_short_form_stories",
        isMagazine: item.productCategory?.glProductGroup?.symbol === "gl_digital_periodicals",
        releaseDate: {
            displayString: item.releaseDate?.displayString,
            date: new Date(item.releaseDate?.date || NaN),
            isPrerelease: new Date(item.releaseDate?.date || NaN) >= new Date(),
        },
        postPurchaseMessageShort: item.postPurchaseString?.shortMessage?.displayString?.text,
        postPurchaseMessageLong: item.postPurchaseString?.longMessage?.displayString?.text,
        whisperSyncForVoice: item.whisperSyncForVoice,
        loaded: true,
        loadFailed: false,
    };

    debug.log(newItem);

    return newItem;
};

const convertPicassoItem = (item: PicassoAsinMetadata): QuickViewAsinMetadata => {
    debug.log(item);
    const buyOfferCta = item.callToActions?.find(cta => isUnconditionalCtaOfType(cta, "Buy"));
    const preOrderOfferCta = item.callToActions?.find(cta => isUnconditionalCtaOfType(cta, "Preorder"));
    const newItem: QuickViewAsinMetadata = {
        asin: item.asin,
        physicalId: item?.productImage?.physicalId,
        title: item.title,
        authors: item?.authorList
            ?.filter((author) => author.roleList.includes("author") || author.roleList.includes("Author"))
            .map((author) => author.name)
            .join(", "),
        description: item.description?.pop(),
        overview: item.overview,
        // If reviewsSummary is missing, set to `null` so loading placeholder is hidden
        reviewsSummary: item.reviewsSummary ?? null,
        series: item.series,
        audibleUrl: item.audibleUrl,
        audibleDisplayText: item.audibleDisplayText,
        isTandem: item.isTandem,
        kindleProgram: item.kindleProgram,
        sellerOfRecord: (buyOfferCta?.offer?.pricingBusinessModel?.value?.displaySellerOfRecord) ? buyOfferCta?.offer?.pricingBusinessModel?.value?.sellerOfRecordName?.value : undefined,
        preOrderOfferCta: preOrderOfferCta,
        hasSample: item.callToActions?.find(cta => isUnconditionalCtaOfType(cta, "Sample")) !== undefined,
        canBorrow: item.callToActions?.find(cta => isUnconditionalCtaOfType(cta, "Borrow")) !== undefined,
        canBuy: buyOfferCta !== undefined,
        canBuyForFree: buyOfferCta?.offer?.priceMap?.value?.price?.amount === 0,
        canPreOrder: preOrderOfferCta !== undefined,
        hasKuUpsell: item.callToActions?.find(cta => isKuUpsell(cta)) !== undefined,
        isRPL: programCodeFromSticker(item.kindleProgram?.stickerStyleCode) === "READ_PLUS_LISTEN",
        isShortStory: item.glProductGroup === "gl_short_form_stories",
        isMagazine: item.glProductGroup === "gl_digital_periodicals",
        releaseDate: getReleaseDate(item),
        loaded: true,
        loadFailed: false,
    };
    if (!newItem.authors && item.authorList) {
        newItem.authors = item?.authorList.map((author) => author.name).join(", ");
    }
    debug.log(newItem);
    return newItem;
};

const requestAsinMetadata = async (asin: string, mode: string): Promise<QuickViewAsinMetadata> => {
    const isQv = mode === "qv";
    const useNewDataApi = debug.get("enableNewDataApi");
    debug.log(`requestAsinMetadata: ${asin}`);
    if ((isQv && debug.get("disableQvMetadataLoads")) || (!isQv && debug.get("disableBbMetadataLoads"))) {
        await delayPromise(2000);
        const status = await NativeAPI.getConnectionStatus();
        return {
            asin,
            loadFailed: true,
            error: `debug::disableMetadataLoads - ${status?.connectionState}`
        };
    }

    const responseWithPerf = useNewDataApi
        ? await fetchAsinMetadataAapiWithPerf([asin])
        : await fetchAsinMetadataWithPerf([asin]);
    debug.log(`ASIN: ${asin}\n${perfToString(responseWithPerf)}`);
    if (responseWithPerf.isError) {
        return {
            asin,
            loadFailed: true,
            error: responseWithPerf.error
        };
    }
    const response = responseWithPerf.result;
    if (!response || (isQv && (response as Array<PicassoAsinMetadata|AapiAsinMetadata>).length === 0)) {
        return {
            asin,
            loadFailed: true,
            error: 'Invalid response'
        };
    }
    const item = (response as Array<PicassoAsinMetadata|AapiAsinMetadata>)[0];

    debug.log(item);
    try {
        return useNewDataApi
            ? convertAapiItem(item as AapiAsinMetadata)
            : convertPicassoItem(item as PicassoAsinMetadata);
    } catch (exception) {
        return {
            asin,
            loadFailed: true,
            error: `Exception: ${exception}`
        };
    }
};

const batchRequestAsinMetadata = async (asins: string[]): Promise<QuickViewAsinMetadata[]> => {
    debug.log("batchRequestAsinMetadata");
    const responseWithPerf = await fetchAsinMetadataWithPerf(asins).catch((it) => {
        debug.log("batchRequestAsinMetadata.failed");
        debug.log(it);
        return it;
    });
    if (!responseWithPerf) {
        // TODO: Is there a world where this happens instead of the other promise being rejected?
        throw Error("batchRequestAsinMetadata: Invalid ASIN metadata response");
    }
    const response = responseWithPerf.result;
    debug.log(`ASIN: ${asins.join(",")}\n${perfToString(responseWithPerf)}`);

    return response.map(convertPicassoItem);
};

const convertAsinOffers = (asinOffers: AsinOffers): PersonalizedAction[] => {
    debug.log(asinOffers);
    const offers = asinOffers.resources
        ?.pop()
        ?.personalizedActionOutput?.personalizedActions?.filter((action) => !action?.offer?.conditional);
    if (!offers) {
        debug.error(`Invalid ASIN offers response ${asinOffers.httpStatusCode} ${asinOffers.status}`);
    }
    debug.log(offers);
    return offers || [];
};

const requestAsinOffers = async (asin: string): Promise<PersonalizedAction[]> => {
    if (debug.get("disableBiFrostLoads")) {
        return new Promise(() => { /* */ });
    }
    const fetchKey = `${asin}-offers`;
    debug.log(`requestAsinOffers: ${fetchKey}`);
    const maybeActiveFetch = activeFetches.get(fetchKey);
    if (maybeActiveFetch) {
        debug.log("Found active fetch for key: " + fetchKey);
        return maybeActiveFetch;
    }
    const fetchPromise = withRetries(() => fetchAsinOffers(asin)).then((offers) => convertAsinOffers(offers));
    activeFetches.set(fetchKey, fetchPromise);
    return fetchPromise.finally(() => activeFetches.delete(fetchKey));
};

const getBackoffDelayForMetadataRetry = (failures: number) => {
    const maxDelay = 15000; // 15 seconds
    const defaultDelay = failures * Math.ceil(failures / 10) * 100;
    if (defaultDelay > maxDelay) {
        const jiggle = (failures % 10) * (Date.now() % 100) + (Date.now() % 100);
        return maxDelay + jiggle;
    }
    return defaultDelay;
}

// Don't actually make a request if we know we're offline
const requestAsinMetadataIfOnline = async (asin: string, mode: string): Promise<QuickViewAsinMetadata> => {
    return navigator.onLine
        ? requestAsinMetadata(asin, mode)
        : { asin, loadFailed: true, error: `navigator.onLine: ${navigator.onLine}` };
};

const fetchMetadata = async (asin: string, mode: string): Promise<QuickViewAsinMetadata> => {
    const failures = asinLoadFailures.get(getAsinKey(asin, mode));
    if (failures) {
        // Add some retry backoff
        const delay = getBackoffDelayForMetadataRetry(failures);
        debug.log(`fetchMetadata: backing off for ${delay}ms before fetching ${asin}, failure count: ${failures}`);
        return delayPromise(delay).then(() => requestAsinMetadataIfOnline(asin, mode));
    }
    return requestAsinMetadataIfOnline(asin, mode);
};

export const batchGetMetadata = async (asins: string[]) => {
    const mode = "qv";
    const fetchKey = asins.join(",");
    debug.log(`batchGetMetadata: ${fetchKey}`);

    if (debug.get("disableQvMetadataLoads")) {
        return new Promise(() => { /* */ });
    }

    const maybeActiveFetch = activeFetches.get(fetchKey);
    if (maybeActiveFetch) {
        debug.log("Found active fetch for key: " + fetchKey);
        return maybeActiveFetch;
    }

    const unfetchedAsins = asins.filter(it => (!activeFetches.has(getAsinKey(it, mode)) && !asinMetadataMap.has(getAsinKey(it, mode)) && it.length === 10));

    if (unfetchedAsins.length === 0) {
        return Promise.resolve([]);
    }

    // Break unfetched ASINs array down into 30 ASIN batches.
    // This should be handled on the back end instead, since the 30 ASIN limit is in AAPI, not the cache
    const promises = [];
    const batchSize = 30;
    for (let offset = 0; offset < unfetchedAsins.length; offset += batchSize) {
        promises.push(
            batchRequestAsinMetadata(unfetchedAsins.slice(offset, offset + batchSize))
                .then((mds) => {
                    mds.forEach((md) => asinMetadataMap.set(getAsinKey(md.asin, mode), md));
                    return mds;
                })
        );
    }
    const batchPromise = Promise.all(promises);

    activeFetches.set(fetchKey, batchPromise);

    return batchPromise.finally(() => activeFetches.delete(fetchKey));
};

export const getOffers = async (asin: string): Promise<PersonalizedAction[] | undefined> => {
    const offers = offersMap.get(asin);
    if (offers) {
        return offers;
    }
    if (debug.get("disableBiFrostLoads")) {
        return new Promise(() => { /* */ });
    }
    const offersPromise = requestAsinOffers(asin);
    offersMap.set(asin, offersPromise);
    offersPromise.catch(() => { offersMap.delete(asin); });
    return offersPromise;
};

const getAsinKey = (asin: string, mode: string) => `${asin}_${mode}`;

const getMetadataBase = async (asin: string, mode: string): Promise<QuickViewAsinMetadata> => {
    if (asin.length !== 10) {
        return {
            asin,
            loadFailed: true,
            unrecoverableError: true,
            error: "Invalid ASIN",
        };
    }
    const asinKey = getAsinKey(asin, mode);
    const maybeActiveFetch = activeFetches.get(asinKey);
    if (maybeActiveFetch) {
        debug.log("Found active fetch for key: " + asinKey);
        return maybeActiveFetch;
    }

    const metadata = asinMetadataMap.get(asinKey);
    if (metadata) {
        return metadata;
    }

    const metadataPromise = fetchMetadata(asin, mode).then((metadata) => {
        if (metadata.loadFailed) {
            asinLoadFailures.set(asinKey, (asinLoadFailures.get(asinKey) || 0) + 1);
        } else {
            asinMetadataMap.set(asinKey, metadata);
        }
        return metadata;
    });

    activeFetches.set(asinKey, metadataPromise);
    return metadataPromise.finally(() => activeFetches.delete(asinKey));
};

export const getQvMetadata = async (asin: string): Promise<QuickViewAsinMetadata> => {
    return getMetadataBase(asin, "qv") as Promise<QuickViewAsinMetadata>;
}

export const getBbMetadata = async (asin: string): Promise<QuickViewAsinMetadata> => {
    return getMetadataBase(asin, "bb") as Promise<QuickViewAsinMetadata>;
}

export type RecsItem = {
    asin: string;
    title?: string;
    category?: string;
    images?: {
        hiRes?: ImageMetadata;
        lowRes?: ImageMetadata;
    };
};

export type TagsItem = {
    id?: string;
    name?: string;
    imageURL?: string;
};

export type RecsResponse = {
    asin: string;
    recs?: RecsItem[];
    tags?: TagsItem[];
    loadFailed?: boolean;
    unrecoverableError?: boolean;
    error?: string;
    hasNextPage?: boolean;
    nextPageToken?: string;
};

const convertRecs = (asin: string, response: PerfWrapper<any>): RecsResponse => {
    debug.log(response);
    if (response.isError) {
        return {
            asin,
            loadFailed: true,
            unrecoverableError: response.error?.includes("403"),
            error: response.error,
        };
    }

    const output: RecsResponse = {
        asin
    };

    const rawRecs = response.result?.data?.getCustomerLibrary?.bookRecommendations;

    if (rawRecs?.pageInfo) {
        output.hasNextPage = rawRecs?.pageInfo?.hasNextPage === true;
        output.nextPageToken = rawRecs?.pageInfo?.endCursor;
    }

    output.recs = rawRecs?.edges
    ?.filter((edge: any) => edge?.node?.book?.product?.category?.glProductGroup?.symbol === "gl_digital_ebook_purchase")
    ?.map((edge: any) => {
        const product = edge?.node?.book?.product;

        const recsItem: RecsItem = {
            asin: product?.asin,
            title: product?.title?.displayString,
            category: product?.category?.glProductGroup?.symbol,
            images: {
                hiRes: product?.images?.images?.find((it: { hiRes: ImageMetadata; }) => it?.hiRes)?.hiRes,
                lowRes: product?.images?.images?.find((it: { lowRes: ImageMetadata; }) => it?.lowRes)?.lowRes,
            },
        };
        return recsItem;
    });

    output.tags = rawRecs?.tags?.map((tag: { tag?: TagsItem; }) => tag?.tag);

    return output;
}

export const getRecs = async (asin: string, tags: string[]): Promise<RecsResponse> => {
    if (debug.get("disableMoreLikeThisLoads")) {
        return {
            asin,
            loadFailed: true,
            unrecoverableError: false,
            error: "debug::disableMoreLikeThisLoads",
        };
    }

    if (asin.length !== 10) {
        return {
            asin,
            loadFailed: true,
            unrecoverableError: true,
            error: "Invalid ASIN",
        };
    }

    const fetchKey = `recs_${asin}_${tags.join(",")}`;
    const maybeActiveFetch = activeFetches.get(fetchKey);
    if (maybeActiveFetch) {
        debug.log("Found active fetch for key: " + fetchKey);
        return maybeActiveFetch;
    }

    const recs = asinRecsMap.get(fetchKey);
    if (recs) {
        return recs;
    }

    const recsPromise = fetchAsinRecsFromDatamate(asin, tags).then((response) => convertRecs(asin, response));

    recsPromise.then(data => {
        if (!data.loadFailed) {
            asinRecsMap.set(fetchKey, data);
        }
    });

    activeFetches.set(fetchKey, recsPromise);
    return recsPromise.finally(() => activeFetches.delete(fetchKey));
};

export const isSponsoredAsin = (input: QuickViewAsinMetadata) => !!(input?.additionalData?.adClickLogUrl);

const calcPageNumberAndPageSize = (first: number, last: number) => {
    let pn = 1, ps = 4;
    do {
        pn = Math.floor((first - 1) / ps); // get page number that contains first item
        const firstItem = (pn * ps) + 1; // first item in the page of results
        const lastItem = firstItem + (ps - 1); // last item in the page of results
        if (lastItem >= last) { // if our target is on this page, we're done
            break
        }
        ps += 1;
    } while (ps < 25); // prevent infinite loop
    return {
        pageNumber: pn + 1,
        pageSize: ps,
    };
};

const populateVellaEpisodeData = async (data: VellaProduct) => {
    debug.log(`populateVellaEpisodeData: ${data.asin} ${data.customerLastReadEpisodeNumber}`);
    const collectionSize = data.collectionSize || 0;
    const lastReadEpisodeNumber = data.customerLastReadEpisodeNumber || 0;
    data.firstDisplayedEpisodeNumber = Math.max(1, Math.min(lastReadEpisodeNumber, collectionSize - 3));
    data.lastDisplayedEpisodeNumber = Math.min(collectionSize, data.firstDisplayedEpisodeNumber + 3);
    if (data.lastDisplayedEpisodeNumber > 0) {
        const {pageNumber, pageSize} = calcPageNumberAndPageSize(data.firstDisplayedEpisodeNumber, data.lastDisplayedEpisodeNumber);
        data.episodes = (await fetchVellaEpisodes(data.asin || "", pageNumber, pageSize)).episodes;
    }
    data.nextEpisode = data.episodes?.find(ep => (ep.episodeNumber || 0) > lastReadEpisodeNumber) || data.episodes?.[data.episodes?.length || 0 - 1];
};

const fetchVellaData = async (asin: string): Promise<VellaProduct> => {
    debug.log(`fetchVellaData: ${asin}`);
    const vellaProductPromise = fetchVellaProduct(asin);
    const vellaFollowPromise = fetchVellaStoryFollowInfo(asin);
    const vellaLikeCountPromise = fetchVellaLikeCount(asin);
    return vellaProductPromise.then(async data => {
        const actualData = data[asin];
        await populateVellaEpisodeData(actualData);
        actualData.likeCount = await vellaLikeCountPromise;
        actualData.followData = await vellaFollowPromise;
        debug.log(actualData);
        return actualData;
    });
};

export const refreshVellaEpisodeData = async (asin: string, lastReadEpisodeNumber: number): Promise<VellaProduct> => {
    debug.log(`refreshVellaEpisodeData: ${asin} ${lastReadEpisodeNumber}`);
    const data = await getVellaData(asin); // will potentially fetch from cache
    if (data.customerLastReadEpisodeNumber !== lastReadEpisodeNumber) {
        data.customerLastReadEpisodeNumber = lastReadEpisodeNumber;
        const collectionSize = data.collectionSize || 0;
        data.firstDisplayedEpisodeNumber = Math.max(1, Math.min(lastReadEpisodeNumber, collectionSize - 3));
        data.lastDisplayedEpisodeNumber = Math.min(collectionSize, data.firstDisplayedEpisodeNumber + 3);
        // Do a sanity check, in case we already have all the needed episodes
        if (!data.episodes?.find(ep => ep.episodeNumber === data.firstDisplayedEpisodeNumber) || !data.episodes.find(ep => ep.episodeNumber === data.lastDisplayedEpisodeNumber)) {
            await populateVellaEpisodeData(data);
        }
    }
    return data;
};

export const getVellaData = async (asin: string): Promise<VellaProduct> => {
    if (debug.get("disableVellaDataLoads")) {
        await delayPromise(2000);
        return Promise.reject("debug::disableVellaDataLoads");
    }
    const fetchKey = `${asin}-vella`;
    const maybeActiveFetch = activeFetches.get(fetchKey);
    if (maybeActiveFetch) {
        debug.log("Found active fetch for key: " + fetchKey);
        return maybeActiveFetch;
    }
    const data = vellaDataMap.get(asin);
    if (data) {
        return data;
    }
    const vellaFetchPromise = fetchVellaData(asin);
    activeFetches.set(fetchKey, vellaFetchPromise);
    return vellaFetchPromise
        .then(data => { vellaDataMap.set(asin, data); return data; })
        .finally(() => activeFetches.delete(fetchKey));
};
