import React, { useContext, useEffect, useState } from "react";
import styles from "./ChangeoverToast.module.scss";
import DeviceContext from "src/contexts/DeviceContext";

type PropTypes = {
    message: string;
    dismiss: () => void;
};

const ChangeoverToast: React.FC<PropTypes> = ({ message, dismiss }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const [isShowingToast, setShowingToast] = useState(false);
    const timeMessageShownMS = 1000;
    useEffect(() => {
        setShowingToast(true);
        const timeout = setTimeout(() => {
            setShowingToast(false);
            setTimeout(dismiss, Number(styles.fadeOutDurationMS));
        }, Number(styles.fadeInDurationMS) + timeMessageShownMS);
        return () => clearTimeout(timeout);
    }, [dismiss]);

    const getClassName = () => {
        return `${styles[deviceContext.theme]} ${styles.toast} ${
            isShowingToast ? styles.toastShown : styles.toastHide
        }`;
    };

    return (
        <div className={getClassName()}>
            <div className={styles.message} aria-live="polite" role="alert">{message}</div>
        </div>
    );
};

export default ChangeoverToast;
