import React from "react";

export interface AudioPlayerContextI {
    src?: string;
    setSrc: (src: string) => void;
}

const noOp = () => { /**/ };
const AudioPlayerContext = React.createContext<AudioPlayerContextI>({ setSrc: noOp });

export default AudioPlayerContext;
