import React, { useCallback, useEffect, useState } from "react";
import FollowManagerContext from "src/contexts/FollowManagerContext";
import { followVellaStory, unfollowVellaStory } from "src/utils/ajaxUtils";
import debug from "src/utils/debugUtils";

type PropTypes = {
    children?: React.ReactNode;
};

const followDataMap = new Map<string, VellaFollowData>();

const FollowManager: React.FC<PropTypes> = ({ children }) => {
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(0);

    useEffect(() => {
        return () => {
            followDataMap.clear();
        };
    }, []);

    const updateFollowData = useCallback((asin: string, data?: VellaFollowData) => {
        if (data) {
            debug.log(`updateFollowData ${asin}`);
            debug.log(data);
            followDataMap.set(asin, data);
            setLastUpdateTimestamp(Date.now());
        }
    }, []);

    const isFollowed = useCallback((asin: string) => {
        return !!followDataMap.get(asin)?.isFollowing;
    }, []);
    
    const follow = useCallback(async (asin: string) => {
        debug.log(`follow ${asin}`);
        if (isFollowed(asin)) {
            return true;
        }
        const followData = followDataMap.get(asin);
        if (!followData || !followData.csrf) {
            return false;
        }
        await followVellaStory(asin, followData.csrf);
        followData.isFollowing = true;
        setLastUpdateTimestamp(Date.now());
        return true;
    }, [isFollowed]);
    
    const unfollow = useCallback(async (asin: string) => {
        debug.log(`unfollow ${asin}`);
        if (!isFollowed(asin)) {
            return true;
        }
        const followData = followDataMap.get(asin);
        if (!followData || !followData.csrf) {
            return false;
        }
        await unfollowVellaStory(asin, followData.csrf);
        followData.isFollowing = false;
        setLastUpdateTimestamp(Date.now());
        return true;
    }, [isFollowed]);

    return (
        <FollowManagerContext.Provider
            value={{
                isFollowed,
                follow,
                unfollow,
                updateFollowData,
                lastUpdateTimestamp: lastUpdateTimestamp,
            }}
        >
            {children}
        </FollowManagerContext.Provider>
    );
};

export default FollowManager;
