import debug from "./debugUtils";

import { getWeblabTreatments, getWeblabTreatmentsAndTrigger, recordWeblabTrigger } from "./ajaxUtils";

const weblabTreatmentCache = new Map<string, string>();
const weblabTriggerCache = new Set<string>;

const getTreatment = async (weblabId: string) => {
    debug.log(`getTreatment: ${weblabId}`);
    const cachedTreatment = weblabTreatmentCache.get(weblabId);
    if (cachedTreatment) {
        debug.log(`getTreatment: ${weblabId} - cache hit: ${cachedTreatment}`);
        return cachedTreatment;
    }
    return getWeblabTreatments([weblabId])
        .then(treatments => {
            const treatment = treatments[weblabId];
            weblabTreatmentCache.set(weblabId, treatment);
            debug.log(`getTreatment: ${weblabId} - caching treatment: ${treatment}`);
            return treatment
        });
};

const getTreatmentAndTrigger = async (weblabId: string) => {
    debug.log(`getTreatmentAndTrigger: ${weblabId}`);
    const cachedTreatment = weblabTreatmentCache.get(weblabId);
    if (cachedTreatment) {
        debug.log(`getTreatmentAndTrigger: ${weblabId} - cache hit: ${cachedTreatment}`);
        if (!weblabTriggerCache.has(weblabId)) { triggerWeblab(weblabId); }
        return cachedTreatment;
    }
    return getWeblabTreatmentsAndTrigger([weblabId])
        .then(treatments => {
            const treatment = treatments[weblabId];
            weblabTreatmentCache.set(weblabId, treatment);
            weblabTriggerCache.add(weblabId);
            debug.log(`getTreatmentAndTrigger: ${weblabId} - caching treatment and trigger: ${treatment}`);
            return treatment
        });
};


const triggerWeblab = async (weblabId: string) => {
    if (weblabTriggerCache.has(weblabId)) {
        debug.log(`triggerWeblab: ${weblabId} - cache hit`);
        return;
    }
    return recordWeblabTrigger([weblabId]).then(() => {
        weblabTriggerCache.add(weblabId);
        debug.log(`triggerWeblab: ${weblabId} - caching trigger`);
    });
};


const isT1 = async (weblabId: string) => (await getTreatment(weblabId)) === "T1";
const isT1AndTrigger = async (weblabId: string) => (await getTreatmentAndTrigger(weblabId)) === "T1";

const weblabUtils = {
    isT1,
    isT1AndTrigger,
    triggerWeblab,
};

export default weblabUtils;
