import React, { useState, useContext, useEffect, memo, useRef, useCallback, forwardRef } from "react";
import { fetchCustomerReviews } from "src/utils/ajaxUtils";
import CustomerReview from "../CustomerReview/CustomerReview";
import styles from "./CustomerReviews.module.scss";
import TranslationsContext from "src/contexts/TranslationsContext";
import { NativeAPI } from "src/utils/deviceUtils";
import { getReviewsUrl } from "src/utils/asinUtils";
import DeviceContext from "src/contexts/DeviceContext";
import Spinner from "../Spinner/Spinner";
import debug from "src/utils/debugUtils";
import { recordBehavioralMetric } from "src/utils/metricsUtils";

interface CustomerReviewsProps {
    asin: string;
    title?: string;
    numberOfReviews?: string;
    getScrollData: () => { scrollAreaScrollTop: number; miniHeaderOffset: number; } | undefined;
}

const CustomerReviews = forwardRef<HTMLDivElement, CustomerReviewsProps>(function CustomerReviews({ asin, title, numberOfReviews, getScrollData }, ref) {
    const deviceContext = useContext(DeviceContext);
    const [customerReviews, setCustomerReviews] = useState<CustomerReview[]>([]);
    const [customerReviewsRetrieved, setCustomerReviewsRetrieved] = useState(false);
    const customerReviewsRef = useRef<HTMLDivElement | null>(null);
    const translations = useContext(TranslationsContext);
    const customerReviewsTitleText = translations.getText("customer-reviews");

    useEffect(() => {
        if (!ref) { return; }
        if (typeof ref === "function") {
            ref(customerReviewsRef.current);
        } else {
            ref.current = customerReviewsRef.current;
        }
    }, [ref]);

    const fetchReviews = useCallback(() => {
        if (debug.get("disableCustomerReviewLoads")) {
            return;
        }
        fetchCustomerReviews(asin).then((response: PicassoCustomerReviewsData) => {
            setCustomerReviews(response.customerReviews.slice(0, 5)); // Keep first 5 reviews only
            setCustomerReviewsRetrieved(true);
            recordBehavioralMetric({ namespace: "CustomerReviews", qv_asin: asin }, "ReviewsFetched.Count", response.customerReviews.length);
        });
    }, [asin]);

    const openReviewsPage = () => {
        recordBehavioralMetric({ namespace: "CustomerReviews", qv_asin: asin }, "SeeAllCustomerReviews.Click", 1);
        NativeAPI.openWebPage(getReviewsUrl({ asin }), title ?? customerReviewsTitleText);
    };

    useEffect(() => {
        if (!debug.get("delayLoadingCustomerReviewsUntilVisible")) {
            if (!customerReviewsRetrieved) {
                fetchReviews();
            }
            return;
        }

        const intersectionObserver = new IntersectionObserver((entries, observer) => {
            if (entries.filter((entry) => entry.isIntersecting).length > 0) {
                if (!customerReviewsRetrieved) {
                    fetchReviews();
                    observer.disconnect();
                }
            }
        }, {
            threshold: 0,
        });

        if (customerReviewsRef.current) {
            intersectionObserver.observe(customerReviewsRef.current);
        }

        return () => {
            intersectionObserver?.disconnect();
        };
    }, [customerReviewsRetrieved, fetchReviews]);

    const getComponent = () => {
        if (customerReviewsRetrieved) {
            return (
                <>
                    <div className={[styles.customerReviews, styles[deviceContext.theme]].join(" ")}>
                        {customerReviews.length
                            ? customerReviews.map((customerReview, index) => {
                                  return <CustomerReview key={index} review={customerReview} asin={asin} getScrollData={getScrollData}/>;
                              })
                            : translations.formatText("no-customer-reviews-some-ratings", { ratingCount: numberOfReviews })}
                    </div>
                    {customerReviews.length > 0 && (
                        <div
                            className={[styles.seeAllReviewsLink, styles[deviceContext.theme]].join(" ")}
                            onClick={openReviewsPage}
                            role="button"
                            tabIndex={0}
                        >
                            {translations.getText("see-all-customer-reviews")}
                        </div>
                    )}
                </>
            );
        }
        return (
            <div style={{ margin: "12px" }}>
                <Spinner />
            </div>
        );
    };

    return (
        <>
            <div 
                className={[styles.label, styles[deviceContext.theme]].join(" ")}
                ref={customerReviewsRef}
                tabIndex={-1}
            >
                {customerReviewsTitleText}
            </div>
            {getComponent()}
        </>
    );
});

export default memo(CustomerReviews);
