import React from "react";

export interface FollowManagerContextI {
    isFollowed: (asin: string) => boolean;
    follow: (asin: string) => Promise<boolean>;
    unfollow: (asin: string) => Promise<boolean>;
    updateFollowData: (asin: string, data?: VellaFollowData) => void;
    lastUpdateTimestamp: number;
}

const noOpBoolPromise = async () => false;
const FollowManagerContext = React.createContext<FollowManagerContextI>({
    isFollowed: () => false,
    follow: noOpBoolPromise,
    unfollow: noOpBoolPromise,
    updateFollowData: () => { /* no op */ },
    lastUpdateTimestamp: 0,
});

export default FollowManagerContext;
