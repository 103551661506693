import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./ReturnAndBorrow.module.scss";
import { BorrowedProduct, getBorrows } from "src/utils/ajaxUtils";
import { getCoverImageUrlFromAsinAndHeight, getCoverImageUrlFromPhysicalIdAndHeight } from "src/utils/asinUtils";
import BorrowManagerContext, { onFailure, onSuccess } from "src/contexts/BorrowManagerContext";
import debug from "src/utils/debugUtils";
import DeviceContext from "src/contexts/DeviceContext";
import { getProgramLogo } from "src/utils/programLogoUtils";
import TranslationsContext from "src/contexts/TranslationsContext";
import Spinner from "../Spinner/Spinner";
import focusHelper from "src/utils/focusHelper";
import { recordOperationalMetric } from "src/utils/metricsUtils";

type PropTypes = {
    data: BorrowLimitData;
    callback: VoidFunction;
    dismiss: () => void;
};

type BookImagePropTypes = {
    item: BorrowedProduct;
    isSelected: boolean;
    selectionToggle: () => void;
};

const BookImage: React.FC<BookImagePropTypes> = ({ item, isSelected, selectionToggle }: BookImagePropTypes) => {
    const targetHeight = 300;
    const imageData = item?.product?.productImages?.images?.[0]?.lowRes;
    const altText = item?.product?.productImages?.altText;
    const imageUrl = imageData?.physicalId
        ? getCoverImageUrlFromPhysicalIdAndHeight( imageData.physicalId, targetHeight, imageData.extension )
        : getCoverImageUrlFromAsinAndHeight(item?.product?.asin ?? "", targetHeight);

    return (
        <div className={styles.bookCover} role="checkbox" aria-checked={isSelected} onClick={selectionToggle} tabIndex={0} aria-label={altText}>
            <img
                className={isSelected ? styles.selectedBook : styles.unselectedBook}
                src={imageUrl}
                alt={""}
                aria-hidden={true}
            />
            {isSelected && (
                <div className={styles.checkMarkContainer}>
                    <div className={styles.checkMarkIcon} />
                </div>
            )}
        </div>
    );
};

const ReturnAndBorrow: React.FC<PropTypes> = ({ data, dismiss, callback }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const borrowManagerContext = useContext(BorrowManagerContext);
    const [borrowsFetched, setBorrowsFetched] = useState(false);
    const [fetchInProgress, setFetchInProgress] = useState(false);
    const [borrows, setBorrows] = useState<BorrowedProduct[]>([]);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const a11yInitialTarget = useRef<HTMLDivElement>(null);

    const fetchBorrows = useCallback(() => {
        if (borrowsFetched || fetchInProgress) {
            return;
        }
        setFetchInProgress(true);
        const program = data.action.actionProgram;
        getBorrows(data.asin, program.programCode, program.channelCode)
            .then((response) => {
                debug.log(response);
                setBorrows(response.borrowedProducts || []);
                setBorrowsFetched(true);
            })
            .finally(() => setFetchInProgress(false));
    }, [borrowsFetched, data.action.actionProgram, data.asin, fetchInProgress]);

    useEffect(() => {
        fetchBorrows();
    }, [fetchBorrows]);

    useEffect(() => {
        focusHelper.requestFocus(a11yInitialTarget.current);
    }, []);

    const selectItem = (index: number) => setSelectedItems([...selectedItems, index]);

    const deselectItem = (index: number) => setSelectedItems(selectedItems.filter((it) => it !== index));

    const toggleItem = (index: number) => {
        const isSelected: boolean = selectedItems.indexOf(index) !== -1;
        if (isSelected) {
            deselectItem(index);
        } else {
            selectItem(index);
        }
    };

    // TODO: Invalidate metadata for any returns and make sure the borrow shows as borrowed afterwards
    const returnAndBorrow = () => {
        const metricsContext = {namespace: "ReturnAndBorrow", qv_asin: data.asin};
        recordOperationalMetric(metricsContext, `ReturnAndContinueTapped`, 1.0);
        recordOperationalMetric(metricsContext, `ReturnAsinsCount`, selectedItems.length);
        Promise.all(selectedItems.map((index) => borrowManagerContext.returnAsin(borrows[index]?.asin || "")))
            .then(() => {
                const asin = data.asin;
                const actionType = data.action.actionType;
                borrowManagerContext
                    .redeemOfferByActionType(asin, actionType, callback)
                    .then(onSuccess((result) => {
                        debug.log(`Success ${asin} - ${actionType}: ${result.reasonCode}`);
                        recordOperationalMetric(metricsContext, `Redeem.${actionType}.success`, 1.0);
                    }))
                    .then(onFailure((result) => {
                        debug.error(`Failure ${asin} - ${actionType}: ${result.reasonCode}`);
                        recordOperationalMetric(metricsContext, `Redeem.${actionType}.success`, 0.0);
                        recordOperationalMetric(metricsContext, `Redeem.${actionType}.failure.${result.reasonCode}`, 1.0);
                    }))
                    .catch(error => {
                        debug.error(`Failed to redeem offer ${asin} - ${actionType} error: ${error}`);
                        recordOperationalMetric(metricsContext, `Redeem.${actionType}.failure.ERROR`, 1.0, "" + error);
                        return error;
                    });
            })
            .finally(dismiss);
    };

    const cancel = () => {
        recordOperationalMetric({namespace: "ReturnAndBorrow", qv_asin: data.asin}, `CancelTapped`, 1.0);
        dismiss();
    }

    const logoForProgram = getProgramLogo(data.action.actionProgram.programCode, deviceContext.theme, deviceContext.domain);

    const itemContainerClassName = `${styles.itemContainer} ${styles[deviceContext.theme]}`;

    return (
        <div className={itemContainerClassName}>
            <div className={styles.chevronIconContainer}>
                <div className={styles.chevronIcon} onClick={dismiss} role="button" tabIndex={0} aria-label={translations.getText("dismiss")}/>
            </div>
            { logoForProgram && (
                <img className={`${styles.subscriptionProgramImage} ${styles[logoForProgram.extraStyleName || ""]}`} src={logoForProgram.src} alt={translations.getText(logoForProgram.altKey)} />
            )}
            <header className={styles.header} ref={a11yInitialTarget} tabIndex={-1}>
                {translations.getText("return-title-to-continue")}
            </header>
            <header className={styles.headerDescription}>
                {translations.getText("borrow-limit-description")}
            </header>

            <main className={styles.main}>
                {!borrowsFetched && (<div className={styles.spinnerBox}><Spinner /></div>)}

                {borrowsFetched && (
                    <div role="fieldset" aria-multiselectable="true" className={styles.horizontalScroller}>
                        {borrows.map((item, index) => {
                            const isSelected: boolean = selectedItems.indexOf(index) !== -1;
                            return (
                                <BookImage
                                    key={item.loanId}
                                    item={item}
                                    isSelected={isSelected}
                                    selectionToggle={() => toggleItem(index)}
                                />
                            );
                        })}
                    </div>
                )}
            </main>

            <footer className={styles.footer}>
                <button className={styles.cancelButton} onClick={cancel}>
                    {translations.getText("cancel")}
                </button>
                {selectedItems.length > 0 && (
                    <button className={styles.ctaButton} onClick={returnAndBorrow}>
                        {translations.getText("return-and-continue")}
                    </button>
                )}
                {selectedItems.length === 0 && (
                    <button className={`${styles.ctaButton} ${styles.disabledButton} `}>
                        {translations.getText("return-and-continue")}
                    </button>
                )}
            </footer>
        </div>
    );
};

export default ReturnAndBorrow;
