import React from "react";

export interface WorkflowContextI {
    addToList: (asin: string, callback: VoidFunction) => void;
    showBuyBox: (metadata: QuickViewAsinMetadata, callback: VoidFunction) => void;
    borrowLimit: (data: BorrowLimitData, callback: VoidFunction) => void;
    infoMessage: (message: string) => void;
    showDebugGooey: (mode?: string) => void;
    openQv: (asins: QuickViewAsinMetadata[], initialIndex: number) => void;
}

const noOp = () => { /* NoOp */ };
const WorkflowContext = React.createContext<WorkflowContextI>({
    addToList: noOp,
    showBuyBox: noOp,
    borrowLimit: noOp,
    infoMessage: noOp,
    showDebugGooey: noOp,
    openQv: noOp,
});

export default WorkflowContext;
