import React, { useCallback, useContext } from "react";
import styles from "./FollowStoryButton.module.scss";
import WorkflowContext from "src/contexts/WorkflowContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import DeviceContext from "src/contexts/DeviceContext";
import { recordBehavioralMetric } from "src/utils/metricsUtils";
import FollowManagerContext from "src/contexts/FollowManagerContext";

type PropTypes = {
    asin: string;
};

const FollowStoryButton: React.FC<PropTypes> = ({ asin }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const followContext = useContext(FollowManagerContext);
    const workflowContext = useContext(WorkflowContext);
    const translations = useContext(TranslationsContext);
    const following = followContext.isFollowed(asin);

    const handleButtonClick = useCallback(() => {
        recordBehavioralMetric({namespace: "FollowStoryButton", qv_asin: asin}, "FollowStoryButton.click", 1);
        if (following) {
            followContext.unfollow(asin).then((unfollowed) => {
                if (unfollowed) {
                    workflowContext.infoMessage(translations.getText("vella-unfollowed-toast"));
                }
            });
        } else {
            followContext.follow(asin).then((followed) => {
                if (followed) {
                    workflowContext.infoMessage(translations.getText("vella-followed-toast"));
                }
            });
        }
    }, [asin, followContext, following, translations, workflowContext]);

    return (
        <button type="button" className={[styles.followStoryButton, styles[deviceContext.theme]].join(" ")} onClick={handleButtonClick}>
            {following
                ? translations.getText("vella-following")
                : translations.getText("vella-follow-story")
            }
        </button>
    );
};

export default FollowStoryButton;

