import React, { useCallback, useContext, useRef } from "react";
import styles from "./PurchaseFlowButton.module.scss";
import WorkflowContext from "src/contexts/WorkflowContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import { recordBehavioralMetric } from "src/utils/metricsUtils";
import focusHelper from "src/utils/focusHelper";

type PropTypes = {
    metadata: QuickViewAsinMetadata;
};

const PurchaseFlowButton: React.FC<PropTypes> = ({ metadata }: PropTypes) => {
    const workflowContext = useContext(WorkflowContext);
    const translations = useContext(TranslationsContext);
    const a11yTarget = useRef<HTMLButtonElement>(null);

    const requestA11yFocus = useCallback(() => {
        focusHelper.requestFocus(a11yTarget.current);
    }, [a11yTarget]);

    const openPurchaseFlow = () => {
        recordBehavioralMetric({namespace: "PurchaseFlowButton", qv_asin: metadata.asin}, "PurchaseFlowButton.click", 1);
        workflowContext.showBuyBox(metadata, requestA11yFocus)
    }

    return (
        <button type="button" className={styles.purchaseFlowButton} onClick={openPurchaseFlow} ref={a11yTarget}>
            <div className={styles.label}>
                {metadata.canPreOrder === true ? translations.getText("preorder-this-book") : translations.getText("buy-this-book")}
                <div className={styles.chevronIconContainer}>
                    <div className={styles.chevronIcon} />
                </div>
            </div>
        </button>
    );
};

export default PurchaseFlowButton;
