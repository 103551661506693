import { getCurrentBaseUrl, setQueryParams } from "src/utils/urlUtils";
import { Theme } from "./themeUtils";

export enum CONTENT_TYPE {
    EBOK = "EBOK",
    EBSP = "EBSP",
    MAGZ = "MAGZ",
}

const BASE_IMAGE_URL = "https://m.media-amazon.com/images";

// This is the preferred image API going forward, whenever we have a PhysicalID available.
// It does not suffer the image caching issues that the original ASIN based approach would
// sometimes encounter, requiring the 1x1 pixel response check.
export function getCoverImageUrlFromPhysicalId(
    physicalId: string,
    width: number,
    fileExtension = "jpg",
    theme?: Theme
): string {
    // Image API documentation:
    // https://w.amazon.com/bin/view/MSA/HowTo/ImageStyleCodes#HBasics
    //
    // _UX{width}_ : Upscale (or downscale) in the x-dimension (width)
    const themeTag = theme === Theme.DARK ? "_BG0,0,0" : "";
    return `${BASE_IMAGE_URL}/I/${physicalId}._${themeTag}_UX${width}.${fileExtension}`;
}

// This is the preferred image API going forward, whenever we have a PhysicalID available.
// It does not suffer the image caching issues that the original ASIN based approach would
// sometimes encounter, requiring the 1x1 pixel response check.
export function getCoverImageUrlFromPhysicalIdAndHeight(
    physicalId: string,
    height: number,
    fileExtension = "jpg",
    theme?: Theme
): string {
    // Image API documentation:
    // https://w.amazon.com/bin/view/MSA/HowTo/ImageStyleCodes#HBasics
    //
    // _UY{height}_ : Upscale (or downscale) in the y-dimension (height)
    const themeTag = theme === Theme.DARK ? "_BG0,0,0" : "";
    return `${BASE_IMAGE_URL}/I/${physicalId}._${themeTag}_UY${height}.${fileExtension}`;
}

// Note: this API is now deprecated, prefer using PhysicalID based API wherever possible.
export function getCoverImageUrlFromAsinAndHeight(asin: string, height: number): string {
    // Image API documentation:
    // https://w.amazon.com/bin/view/MSA/HowTo/ImageStyleCodes#HBasics
    //
    // .01. : Set MerchantID from which image should be resolved (default is "1")
    // _SCLZZZZZZZ_ : Use the Large size of the image as a base for resizing
    // _UY{height}_ : Upscale (or downscale) in the y-dimension (height)
    return `${BASE_IMAGE_URL}/P/${asin}.01._SCLZZZZZZZ_UY${height}.jpg`;
}

export function getCoverImageUrlFromPhysicalIdWithoutDimensions(
    physicalId: string,
    fileExtension = "jpg",
    theme?: Theme
): string {
    const themeTag = theme === Theme.DARK ? "_BG0,0,0" : "";
    return `${BASE_IMAGE_URL}/I/${physicalId}._${themeTag}.${fileExtension}`;
}

export function getDetailsUrl(
    baseUrl: string,
    asin: string,
    shortStory?: boolean,
    reftag?: string,
    theme?: string
): string {
    const url = new URL(`/dp/${asin}`, baseUrl).href;
    const params: { [key: string]: any } = {};

    // Add param `redirectToStory=true` for redirecting Kindle Vella episodes to story detail page
    if (shortStory) {
        params["redirectToStory"] = "true";
    }

    if (reftag) {
        params["ref_"] = reftag;
    }

    if (theme) {
        params["theme"] = theme;
    }

    return setQueryParams(url, params);
}

export function getReviewsUrl(args: { baseUrl?: string; asin: string; reftag?: string; theme?: string }): string {
    const baseUrl = args.baseUrl ? args.baseUrl : getCurrentBaseUrl();
    const url = new URL(`/product-reviews/${args.asin}`, baseUrl).href;
    const params: { [key: string]: any } = {};

    if (args.reftag) {
        params["ref_"] = args.reftag;
    }

    if (args.theme) {
        params["theme"] = args.theme;
    }

    return setQueryParams(url, params);
}
