import React, { useContext, useRef } from "react";
import styles from "./ShareButton.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import { getCurrentBaseUrl } from "src/utils/urlUtils";
import { getDetailsUrl } from "src/utils/asinUtils";
import debug from "src/utils/debugUtils";
import TranslationsContext from "src/contexts/TranslationsContext";
import { isSharingSupported, NativeAPI } from "src/utils/deviceUtils";
import { recordBehavioralMetric, recordOperationalMetric } from "src/utils/metricsUtils";
import focusHelper from "src/utils/focusHelper";

// Inline share SVGs so we can set colors using `currentColor` in `fill`/`stroke`
const ShareIconIOS = () => (
    <svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.92285 6.5L10.6249 2L14.3269 6.5" stroke="currentColor" strokeWidth="2"/>
    <path d="M10.6252 2V18.5" stroke="currentColor" strokeWidth="2"/>
    <path d="M6.7388 9.5H1V26H20.2505V9.5H14.5352" stroke="currentColor" strokeWidth="2" strokeLinejoin="round"/>
    </svg>
);

const ShareIconAndroid = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path d="M17,3.00192584 C18.6557906,3.00192584 19.9980742,4.34420936 19.9980742,6 C19.9980742,7.65579064 18.6557906,8.99807416 17,8.99807416 C16.1578136,8.99807416 15.3967333,8.6508181 14.852131,8.09167815 L9.39501737,11.2112879 C9.46336462,11.4625994 9.49984273,11.7270397 9.49984273,12 C9.49984273,12.2729603 9.46336462,12.5374006 9.39501737,12.7887121 L14.8528622,15.907571 C15.3974144,15.3488708 16.1581905,15.0019258 17,15.0019258 C18.6557906,15.0019258 19.9980742,16.3442089 19.9980742,17.9999996 C19.9980742,19.6557902 18.6557906,20.9980737 17,20.9980737 C15.3442094,20.9980737 14.0019258,19.6557902 14.0019258,17.9999996 C14.0019258,17.7270393 14.0384039,17.462599 14.1067512,17.2112875 L8.64963752,14.0916781 C8.1050353,14.6508181 7.34395493,14.9980742 6.50176856,14.9980742 C4.84597793,14.9980742 3.5036944,13.6557906 3.5036944,12 C3.5036944,10.3442094 4.84597793,9.00192584 6.50176856,9.00192584 C7.34357809,9.00192584 8.10435419,9.3488712 8.64890633,9.9075714 L14.1067512,6.78871207 C14.0384039,6.5374006 14.0019258,6.27296026 14.0019258,6 C14.0019258,4.34420936 15.3442094,3.00192584 17,3.00192584 Z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

type PropTypes = {
    item: QuickViewAsinMetadata;
};

const ShareButton: React.FC<PropTypes> = ({ item }: PropTypes) => {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const self = useRef<HTMLButtonElement>(null);

    const sharingSupported = isSharingSupported();
    if (!sharingSupported && !debug.get("alwaysShowShare")) return null;

    return (
        <button
            type="button"
            className={`${styles[context.theme]} ${styles.shareButton}`}
            aria-label={translations.getText("share")}
            ref={self}
            onClick={() => {
                const titleString = item.isShortStory ? "share-data-vella-title" : "share-data-title";
                const textString = item.isShortStory ? "share-data-vella-text" : "share-data-text";
                const shareTitle = translations.formatText(titleString, { title: item.title });
                const shareText = translations.formatText(textString, { title: item.title, author: item.authors });

                const shareData: ShareData = {
                    title: shareTitle,
                    text: shareText,
                    // TODO add correct reftag
                    url: getDetailsUrl(getCurrentBaseUrl(), item.asin, item.isShortStory, "quick_view_ref_tag"),
                };

                debug.log(shareData);

                const metricsContext = { namespace: "Share", qv_asin: item.asin };
                recordBehavioralMetric(metricsContext, "Share.Click", 1);
                NativeAPI.share(shareData)
                    .then(() => {
                        recordBehavioralMetric(metricsContext, "Success", 1);
                        recordOperationalMetric(metricsContext, "shareButtonError", 0);
                    })
                    .catch(() => {
                        recordBehavioralMetric(metricsContext, "Success", 0);
                        recordOperationalMetric(metricsContext, "shareButtonError", 1.0);
                    })
                    .finally(() => focusHelper.requestFocus(self.current));
            }}
        >
            {context.device.isIOS ? <ShareIconIOS/> : <ShareIconAndroid/>}
        </button>
    );
};

export default ShareButton;
