import React from "react";
import styles from "./RichContent.module.scss";

interface RichContentProps {
    content: RichContent;
}

const handleSemanticContent = (content: RichContent) => {
    const semanticContent = content.semanticContent;
    if (!semanticContent) {
        return null;
    }
    const isB = !!semanticContent.strong;
    const isI = !!semanticContent.emphasized;
    const isBI = isB && isI;
    const childText = semanticContent.content.text;
    if (childText) {
        if (isBI) { return (<b><i dangerouslySetInnerHTML={{ __html: childText }}></i></b>); }
        if (isB) { return (<b dangerouslySetInnerHTML={{ __html: childText }}></b>); }
        if (isI) { return (<i dangerouslySetInnerHTML={{ __html: childText }}></i>); }
        return (<RichContent content={semanticContent.content} />);
    }
    if (isBI) { return (<b><i><RichContent content={semanticContent.content} /></i></b>); }
    if (isB) { return (<b><RichContent content={semanticContent.content} /></b>); }
    if (isI) { return (<i><RichContent content={semanticContent.content} /></i>); }
    return (<RichContent content={semanticContent.content} />);
};

const handleHeading = (content: RichContent) => {
    const heading = content.heading;
    if (!heading) {
        return null;
    }
    const childText = heading.content.text;
    let level = heading.level;
    if (level < 1) { level = 1; }
    if (level > 6) { level = 6; }
    const tag = `h${level}`;
    if (childText) {
        return React.createElement(tag, {
            dangerouslySetInnerHTML: { __html: heading.content.text}
        });
    }
    return React.createElement(tag, {}, (<RichContent content={heading.content} />));
};

const RichContent: React.FC<RichContentProps> = ({ content }) => {
    return (
        <React.Fragment>
            {content.text && (<span dangerouslySetInnerHTML={{ __html: content.text }} className={styles.text} />)}
            {content.semanticContent?.content && handleSemanticContent(content)}
            {content.paragraph && (
                <span className={`${styles.paragraph}`}>
                    <RichContent content={content.paragraph} />
                </span>
            )}
            {(content.list && content.list.type === "UNORDERED") && (
                <ul className={styles.ul}>
                    { content.list.items.map((nestedContent, index) => (
                        <li key={index} >
                            <RichContent content={nestedContent} />
                        </li>
                    ))}
                </ul>
            )}
            {(content.list && content.list.type !== "UNORDERED") && (
                <ol className={styles.ol}>
                    { content.list.items.map((nestedContent, index) => (
                        <li key={index}>
                            <RichContent content={nestedContent} />
                        </li>
                    ))}
                </ol>
            )}
            {content.fragments?.map((nestedContent, index) => (
                <RichContent key={index} content={nestedContent} />
            ))}
            {content.heading && handleHeading(content)}
        </React.Fragment>
    );
};

export default RichContent;
